<template>
    <div>
      <!-- Subject Field -->
      <b-form-group label="Subject">
        <b-form-input v-model="subject"></b-form-input>
      </b-form-group>
  
      <!-- Mail Body using Quill Editor -->
      <b-form-group label="Mail Body">
        <quill-editor v-model="mailBody" :options="editorOptions"></quill-editor>
      </b-form-group>
  
      
      <b-row>
        <b-col cols="4">
            <!-- Sender Name -->
            <b-form-group label="Sender Name">
                <b-form-input v-model="senderName" ></b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="4">
            <!-- Sender Email -->
            <b-form-group label="Sender Email">
                <b-form-input v-model="senderEmail" ></b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="4">
            <!-- Excel File Upload -->
            <b-form-group label="Upload Excel File Containing Recipients">
                <b-form-file @change="handleFileUpload" accept=".xls,.xlsx,.csv"></b-form-file>
            </b-form-group>
        </b-col>
      </b-row>
      
      <!-- Preview Table -->
      <b-table
        :items="excelData"
        :fields="tableFields"
        :per-page="10"
        :current-page="currentPage"
        :busy.sync="isLoading"
      >
        <template #cell(status)="data">
          <span class="text-primary" v-if="data.item.status === 'Success'">
            {{ data.item.status }}
          </span>
          <span class="text-danger" v-else>
            {{ data.item.status }}
          </span>
        </template>
        <template #cell(errorMessage)="data">
          <span class="text-danger">{{ data.item.errorMessage }}</span>
        </template>
      </b-table>
      <b-pagination  v-if="excelData.length > 10"
        v-model="currentPage"
        :total-rows="excelData.length"
        :per-page="10"
      ></b-pagination>
      <!-- Preview Modal -->
      <b-modal id="mail-preview-modal" title="Mail Preview" size="lg" hide-footer>
        <div v-html="previewContent"></div>
      </b-modal>

      <div class="demo-inline-spacing">
        <b-button variant="success" @click="sendMails">Send</b-button>
        <b-button variant="outline-secondary" @click="previewMail">Mail Preview</b-button>
        <b-button variant="outline-secondary" @click="downloadReport" v-if="reportReady">Download Mail Status Report</b-button>
      </div>
      <!-- Mail Preview Button -->
      
  
      <!-- Send Button -->
    
      <!-- Spinner -->
      <div v-if="isSending">
        <b-spinner label="Sending..."></b-spinner>
        <span>Sending emails, please wait...</span>
      </div>
    </div>
  </template>
  
  <script>
  import {
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BModal,
    BRow,
    BCol
  } from 'bootstrap-vue';
  import { quillEditor } from 'vue-quill-editor';
  // import XLSX from 'xlsx';
  import * as XLSX from 'xlsx'
  import { saveAs } from 'file-saver';
  import MailService from '@/services/mail-service';
  import MassMailer from '@/services/massMailer-service'
//   import axios from 'axios';
  
  export default {
    components: {
      BFormGroup,
      BFormInput,
      BFormFile,
      BButton,
      BTable,
      BPagination,
      BSpinner,
      BModal,
      quillEditor,
      BRow,
      BCol
    },
    data() {
      return {
        subject: '',
        mailBody: '',
        senderName: '',
        senderEmail: '',
        senderEmpId: '',
        excelData: [],
        tableFields: [],
        currentPage: 1,
        isLoading: false,
        isSending: false,
        reportReady: false,
        editorOptions: {
          // Quill editor options
        },
        previewContent: '',
      };
    },
    methods: {
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          this.readExcelFile(file);
        }
        this.excelData = []
      },
      readExcelFile(file) {
        this.isLoading = true;
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { defval: '' });
  
          // Normalize keys
          this.excelData = jsonData.map((item) => {
            const normalizedItem = {};
            for (const key in item) {
              const normalizedKey = key.trim().toLowerCase();
              normalizedItem[normalizedKey] = item[key];
            }
            return {
              ...normalizedItem,
              status: '',
              errorMessage: '',
            };
          });
  
          this.tableFields = [
            ...Object.keys(this.excelData[0]).map((key) => ({ key, label: key })),
            { key: 'status', label: 'Status' },
            { key: 'errorMessage', label: 'Error Message' },
          ];
          this.isLoading = false;
        };
        reader.readAsArrayBuffer(file);
      },
      async previewMail() {
        if (this.excelData.length > 0) {
          const firstRecord = this.excelData[0];
          console.log('first record: ', firstRecord)
          const previewData = await this.replacePlaceholders(this.mailBody, firstRecord);
        //   console.log('preview Data: ', this.mailBody, previewData)
          this.previewContent = previewData;
          this.$bvModal.show('mail-preview-modal');
        //   console.log('preview Data: ', previewData)
        } else {
          this.$bvToast.toast('No data to preview', {
            title: 'Error',
            variant: 'danger',
          });
        }
      },
      async replacePlaceholders(template, data) {
        let result = template;
        for (const key in data) {
            // const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special regex chars
            // console.log('esaped key: ', escapedKey)
            // const regex = new RegExp(`<<${key}>>`, 'gi');
            // console.log('esaped key regex: ', regex,data[key])
            result = result.replaceAll(`&lt;&lt;${key}&gt;&gt;`, data[key]);
            // console.log('esaped result: ', result)
        }
        return result;
      },
      async sendMails() {
        if(!this.isLoggedIn) {
            return
        }
        if (!this.senderEmail || !this.senderName || !this.subject || !this.excelData.length > 0) {
          this.$bvToast.toast('Subject,Sender Name,Email and Recipient List are required', {
            title: 'Error',
            variant: 'danger',
          });
          return;
        }
        this.isSending = true;
        this.reportReady = false;
        const mailer_id = new Date()
        for (let i = 0; i < this.excelData.length; i++) {
          const recipient = this.excelData[i];
        //   const mailBodyHTML = this.$refs.quillEditor.quill.root.innerHTML;
          const personalizedBody = await this.replacePlaceholders(this.mailBody, recipient);
        //   console.log('mail content: ', personalizedBody)
        //   let mailResponse = null
          try {
            // mailResponse = await this.sendMailAPI(recipient.email, personalizedBody);
            const data = {
                senderName: this.senderName,
                senderEmail: this.senderEmail,
                recipientEmail: recipient.email,
                subject: this.subject,
                bodyContent: personalizedBody,
            }
            
            MailService.sendEmail(data).then(res => {
                console.log('response: ', res)
                if(res.status === 200){
                    // return {status: 'Success'}
                    this.$set(this.excelData[i], 'status', 'Success');
                    const massMailLog = {
                        sender_id: this.senderEmpId,
                        mail_sent: true,
                        response: '',
                        mailer_id: mailer_id,
                        source: "ATS BULK MAIL",
                        comment: recipient.email + ': ' +  this.subject
                    }
                    MassMailer.createMailer(massMailLog).then(response => {
                        console.log('mass mail log created: ', response)
                    })

                } else {
                    this.$set(this.excelData[i], 'status', 'Failed');
                    const massMailLog = {
                        sender_id: this.senderEmpId,
                        mail_sent: false,
                        response: res.data.error.message || error.response?.data || error.message,
                        mailer_id: mailer_id,
                        source: "ATS BULK MAIL",
                        comment: recipient.email + ': ' +  this.subject
                    }
                    MassMailer.createMailer(massMailLog).then(response => {
                        console.log('mass mail log created: ', response)
                    })
                    this.$set(this.excelData[i], 'errorMessage', res.data.error.message || error.response?.data || error.message);
            //     this.$set(this.excelData[i], 'errorMessage', error.response?.data || error.message || mailResponse.message););
                    // return {status: 'Failed', message: res.data.error.message}
                }
                // return res
            })
            // if(mailResponse) {
            //     console.log('mail response: ', mailResponse)
            // }
            
            // if(mailResponse.status === 'Success') {
                // this.$set(this.excelData[i], 'status', 'Success');
            // } else if (mailResponse.status === 'Failed') {
            //     this.$set(this.excelData[i], 'status', 'Failed');
            //     this.$set(this.excelData[i], 'errorMessage', error.response?.data || error.message || mailResponse.message);
            // }
            
          } catch (error) {
            const massMailLog = {
                sender_id: this.senderEmpId,
                mail_sent: false,
                response: res.data.error.message || error.response?.data || error.message,
                mailer_id: mailer_id,
                source: "ATS BULK MAIL",
                comment: recipient.email + ': ' +  this.subject
            }
            MassMailer.createMailer(massMailLog).then(response => {
                console.log('mass mail log created: ', response)
            })
            this.$set(this.excelData[i], 'status', 'Failed');
            this.$set(this.excelData[i], 'errorMessage', error.response?.data || error.message);
          }
            // Add a delay of 1 second between each email
            await this.sleep(600);
        }
        this.isSending = false;
        this.reportReady = true;
      },
      sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      },
      downloadReport() {
        // Prepare the worksheet data
        const worksheetData = this.excelData.map((item) => {
        // Exclude any Vue.js observer properties
            const { __ob__, ...exportItem } = item;
            return exportItem;
        });

        // Create a worksheet
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);

        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mail Status Report');

        // Generate a buffer
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Create a Blob from the buffer
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        const dateNow = new Date()
        // Use FileSaver to save the file
        saveAs(blob, `Mail_Status_Report_${dateNow}.xlsx`);
    },
    isLoggedIn() {
      const expireIn = localStorage.getItem('expireIn')
      if (Math.floor(Date.now() / 1000 > expireIn)) {
          window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
          ////console.log("called logout")
          localStorage.clear();
          this.$router.push({ name: "auth-login" });
      }
    },
    //   async sendMailAPI(toEmail, bodyContent) {
        
    //     // return axios.post('/sendMail', {
    //     //   senderEmail: this.senderEmail,
    //     //   recipientEmail: toEmail,
    //     //   subject: this.subject,
    //     //   bodyContent: bodyContent,
    //     // });
    //   },
    },
    mounted() {
        this.isLoggedIn()
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.senderName = userData.first_name + ' ' + userData.last_name
        this.senderEmail = userData.email
        this.senderEmpId = userData.emp_id
        // console.log('XLSX.utils:', XLSX.utils);
        // console.log('XLSX.utils.json_to_sheet:', XLSX.utils.json_to_sheet);
    }
  };
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/quill.scss';
  /* Add any necessary styles */
  </style>
  