import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.63:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';
let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.63:5000/api/';
}
class UserProbationService {
    async create(data) {
        try {
            const res = await axios.post(API_URL + 'probation', data,
        { 
            headers: AuthService.authHeader()
        });
        //console.log(res.data)
        return res;
        }
        catch (err) {
            console.log(err)
            return err;
        }
        
    }

    async getAll() {
        try {
            const res = await axios.get(API_URL + 'probation',
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err)
            return err
        }
    }
    async getOne(emp_id) {
        try {
            const res = await axios.get(API_URL + 'probation/' + emp_id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err)
            return err
        }
    }

    async update(id, data) {
        try {
            const res = await axios.put(API_URL + 'probation/' + id, data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err)
            return err
        }
    }

    async delete(emp_id) {
        try {
            const res = await axios.delete(API_URL + 'probation/' + emp_id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err)
            return err
        }
    }

}

export default new UserProbationService();