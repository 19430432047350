import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.63:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.63:5000/api/';
}
class LinkedInPostService {
    async addPost(data) {
        try {
            const res = await axios.post(API_URL + 'post/linkedin', data,
        { 
            headers: AuthService.authHeader()
        });
        //console.log(res.data)
        return res.data;
        }
        catch (err) {
            console.log(err.response)
            return err.response;
        }
        
    }

    async getAllPost(postDate) {
        try {
            const res = await axios.get(API_URL + 'post/linkedin/' + postDate,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async updatePost(data) {
        try {
            const res = await axios.put(API_URL + 'post/linkedin/', data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async deletePost(id) {
        try {
            const res = await axios.delete(API_URL + 'post/linkedin/' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

}

export default new LinkedInPostService();