<template>
    <section id="dashboard-ecommerce" v-if="$can('read','invoice')">
    
    <div>
      <app-collapse class="mt-1 mb-1" style="border-radius: 150px !important">
                    
        <app-collapse-item title="Filter">
        <b-card>
          <div>
            <h5>Select Fields</h5>
             <b-row>
              <b-col col >
                <b-form-group id="invoice-status" label="Invoice Status *" label-for="invoice-status">
                  <v-select
                      v-model="searchFilters.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      placeholder="Select Invoice Status" 
                      :options="statusOptions"
                      :reduce="(option) => option.title"
                  />
                </b-form-group>
              </b-col>  
              <b-col col >
                <b-form-group id="invoice-category" label="Invoice Category *" label-for="invoice-category">
                  <v-select
                      v-model="searchFilters.category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      placeholder="Select Category" 
                      :options="categories"
                      :reduce="(option) => option.title"
                  />
                </b-form-group>
              </b-col>
              <b-col col >
                <b-form-group id="invoice-client" label="Invoice Client *" label-for="invoice-client">
                  <v-select
                      v-model="searchFilters.client"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      placeholder="Select Client" 
                      :options="clients"
                      :reduce="(option) => option.title"
                  />
                  
                </b-form-group>
              
              </b-col>
            </b-row> 
            
            <b-row class="mt-1">
              <b-col md="12" xl="12" xs="12" class="mb-1">
                <b-form-group label="Date Filters">
                    <b-form-radio-group
                        v-model="searchFilters.dateType"
                        :options="dateTypeOptions"
                        class="demo-inline-spacing"
                        name="radio-inline"
                    />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row >
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="Start Date"
                  label-for="start-date"
                >
                  <flat-pickr
                    v-model="searchFilters.startDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <!-- <small v-if="!startDate" class="text-danger">Start Date is Mandatory</small> -->
                </b-form-group>
              </b-col>    
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="End Date"
                  label-for="end-date"
                >
                  <flat-pickr
                    v-model="searchFilters.endDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <!-- <small v-if="!endDate" class="text-danger">End Date is Mandatory</small> -->
                </b-form-group>
              </b-col>
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label=""
                  label-for="">
                  <b-button variant="outline-primary" value="submit" id="submit" type="submit" width="100%" @click="getReport" style="margin-top: 20px">Get Report</b-button>
                  <b-button variant="outline-secondary"  @click="clearFilter" style="margin-top: 20px; margin-left: 10px">Clear Filters</b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="!loading && compiledData">
              
              <download-excel :data="compiledData" :name="filename" v-if="isReadyToDownload">
                <button type="button" class="btn btn-success mb-1">Download Report</button>
              </download-excel>
            </div>  
        </div>
        </b-card>
        </app-collapse-item>
      </app-collapse>  
        <div class="text-right" v-if="$can('create','invoice')"> 
          <router-link :to="'/apps/create-invoice'" >
            <button type="button" class="btn btn-success mb-1">Create Invoice</button>
          </router-link>
        </div>
        <b-row>
          <b-col 
            lg="3"
            sm="6"
          >
            <statistic-card-horizontal
              icon="CheckCircleIcon"
              :statistic = invoice.raised
              :statistic-title="'Raised(₹ ' + invoice.raisedRevenue + ')'"
            />
          </b-col>
          <b-col 
            lg="3"
            sm="6"
          >
            <statistic-card-horizontal
              icon="XCircleIcon"
              color="warning"
              :statistic= invoice.pending
              statistic-title="Invoice Pending"
            />
          </b-col>
          <b-col 
            lg="3"
            sm="6"
          >
            <statistic-card-horizontal
              icon="ThumbsUpIcon"
              color="primary"
              :statistic= invoice.paid
              :statistic-title="'Paid(₹ ' + invoice.paidRevenue + ')'"
            />
          </b-col>
          
          <b-col 
            lg="3"
            sm="6"
          >
            <statistic-card-horizontal
              icon="DollarSignIcon"
              color="info"
              :statistic= invoice.revenue
              statistic-title="Tentative Revenue"
            />
          </b-col>
     
        </b-row>

        <b-card>
            <div v-if="loading">
                <div class="text-center">
                  <b-spinner variant="primary" label="Text Centered" />
                </div>
            </div>
            <div v-if="reportStatus" class="text-danger">{{ reportStatus }}</div>
            <div v-if="!loading && compiledData">

            
              
              
            <b-row>
              <b-col
                md="3"
                sm="3"
                lg="3"
                class="my-1"
              >
                <b-form-group
                  class="mb-0"
                >
                  <label class="d-inline-block text-sm-left mr-50">Per page</label>
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                sm="3"
                lg="3"
                class="my-1"
                style="font-weight: 430;"
              > Total Invoices : {{ compiledData.length }}
            </b-col>
            <b-col
                md="3"
                sm="3"
                lg="3"
                class="my-1"
                style="font-weight: 430;"
              > <span>Email Pending: {{ invoice.emailPending }}</span> <span> Overdue: {{ invoice.overDue }}</span>
            </b-col>
              <b-col
                md="3"
                sm="3"
                lg="3"
                class="my-1"
              >
                <b-form-group
                  label="Filter"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                      trim
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="compiledData"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  style="font-size: 0.8rem; font-weight: 400; line-height: 1.3;"
                >
                  <template #cell(invoice_id)="data" >
                    <router-link v-if="data.item.invoice_id" :to="{ name: 'apps-update-invoice', params: { id: data.item.invoice_id}}">
                        <div class="text-nowrap text-success" >
                            {{ data.item.invoice_id }} 
                        </div>
                    </router-link>
                  </template>
                  <template #cell(candidateList)="data" >
                    <div v-if="data.item.candidateList">
                    <div v-for="candidate in data.item.candidateList" :key="candidate.CAN_ID" style="margin-bottom: 2px">
                      <!-- {{ candidate.CANDIDATE_NAME }} -->
                      <router-link
                                  :to="{
                                    name: 'apps-candidates-view',
                                    params: { id: candidate.cid, jobId: candidate.jid },
                                    query: { position_name: candidate.POSITION_NAME,
                                            client: candidate.CLIENT,
                                            attachments: candidate.attachments,
                                            recruiter: candidate.recruiter,
                                            locations: candidate.locations,
                                            sourcer: candidate.sourcer,
                                            am_name: candidate.account_mgr
                                    }
                                  }"
                                >
                        <div class="text-nowrap" style="font-weight: 430;  width: 220px !important; text-overflow:ellipsis; overflow:hidden ;white-space: initial">
                          {{ candidate.CAN_ID }} - {{ candidate.CANDIDATE_NAME }}
                        </div>
                        
                      </router-link>
                    </div>
                    </div>
                    
                    <div v-if="!data.item.candidateList && data.item.invoice_id">
                      {{ data.item.candidates }}
                    </div>
                    
                    <div v-if="!data.item.invoice_id">
                      <div>
                          {{ data.item.CAN_ID }} - {{ data.item.CANDIDATE_NAME }}
                        </div>
                    </div>
                    
                    <div v-if="data.item.description && data.item.description !== 'null'">
                        {{ data.item.description }}
                    </div>
                    
                  </template>  
                  
                    <!-- <router-link
                                  :to="{
                                    name: 'apps-candidates-view',
                                    params: { id: data.item.cid, jobId: data.item.jid },
                                    query: { position_name: data.item.POSITION_NAME,
                                            client: data.item.CLIENT,
                                            attachments: data.item.attachments,
                                            recruiter: data.item.recruiter,
                                            locations: data.item.locations,
                                            sourcer: data.item.sourcer,
                                            am_name: data.item.account_mgr
                                    }
                                  }"
                                >
                        <div class="text-nowrap" style="font-weight: 430;  width: 150px !important; text-overflow:ellipsis; overflow:hidden ;white-space: initial">
                            {{ data.item.CANDIDATE_NAME }}<br/>
                            {{ data.item.CAN_ID }}
                        </div>
                    </router-link> -->
                  
                  <!-- <template #cell(position_name)="data">
                  <router-link :to="'/apps/view-job/' + data.item.jid">
                  <div class="text-nowrap" style="font-weight: 430;  width: 150px !important; text-overflow:ellipsis; overflow:hidden ;white-space: initial">
                      {{ data.item.POSITION_NAME }} <br/>
                      {{ data.item.JOB_ID }} <br/>
                      {{ data.item.CLIENT }} 
                      </div>
                  </router-link>
                  </template> -->
                
                  <!-- <template #cell(recruiter)="data">
                      <div class="text-nowrap">
                        {{ data.item.rec_name }} (R)<br/>
                        {{ data.item.tl }} (TL)<br/>
                        {{ data.item.p_mgr }} (PM)<br/>
                      </div>
                  </template> -->
                  <template #cell(invoice_date)="data">
                      <div class="text-nowrap">
                        {{ data.item.invoice_date }} <br>
                        <span v-if="data.item.paid_date"> Paid On: {{ data.item.paid_date }}</span>
                      </div>
                      
                  </template>
                  <template #cell(client)="data">
                      <div class="text-nowrap">
                        {{ data.item.client }}
                      </div>
                      <div v-if="!data.item.invoice_id">
                        {{ data.item.CLIENT }}
                      </div>
                  </template>
                  <template #cell(status)="data">
                      <div class="text-nowrap">
                        <b-badge pill class="text-capitalize"
                          :class="resolveInvoiceStatusVariant(data.item.status)">
                            {{ data.item.status }}
                        </b-badge><br>
                        <span>{{ data.item.location }}</span>
                      </div>
                  </template>
                  <template #cell(base_amount)="data">
                      <div class="text-nowrap">
                        {{ data.item.base_amount }}
                      </div>
                      <div class="text-nowrap" v-if="data.item.revenue">
                        {{ data.item.revenue }}
                      </div>
                  </template>
                  <template #cell(gst)="data">
                      <div class="text-nowrap">
                        {{ data.item.gst }}
                      </div>
                  </template>
                  <template #cell(total_amount)="data">
                      <div class="text-nowrap">
                        {{ data.item.total_amount }}
                      </div>
                  </template>
                  <template #cell(actions)="data">  
                    <div class="demo-inline-spacing" v-if="$can('update','invoice')">
                      <router-link v-if="!data.item.invoice_id" :to="{ name: 'apps-create-invoice', query: { invoiceStatus: 'Raised', client: data.item.CLIENT, cid: data.item.cid, jid: data.item.jid, category: 'Recruitment', revenue: data.item.revenue, candidate_name: data.item.CAN_ID + ' - ' + data.item.CANDIDATE_NAME, position_name: data.item.JOB_ID + ' - ' + data.item.POSITION_NAME }}">
                        <span v-b-tooltip.hover.bottom="'Raise Invoice'" v-if="!data.item.invoice_id"><feather-icon size="21" icon="PlusSquareIcon" class="text-info"/></span>
                      </router-link>
                      <span @click="markAsEmailed(data.item)" v-b-tooltip.hover.bottom="'Emailed'" variant="light-secondary" v-if="data.item.status === 'Raised'"><feather-icon size="21" icon="MailIcon" class="text-info"/></span>
                      <router-link v-if="data.item.invoice_id" :to="{ name: 'apps-update-invoice', params: { id: data.item.invoice_id}}">
                        <span v-b-tooltip.hover.bottom="'Edit'" v-if="data.item.status === 'Raised' || data.item.status === 'Paid' || data.item.status === 'Unpaid'"><feather-icon size="21" icon="EditIcon" class="text-secondary"/></span>
                      </router-link>
                      <router-link v-if="data.item.invoice_id" :to="{ name: 'apps-update-invoice', params: { id: data.item.invoice_id}}">
                        <span v-b-tooltip.hover.bottom="'Upload Invoice'" v-if="(data.item.status === 'Raised' || data.item.status === 'Paid' || data.item.status === 'Unpaid') && !data.item.file"><feather-icon size="21" icon="UploadIcon" class="text-warning"/></span>
                      </router-link>
                      <span @click="markAsCancelled(data.item)" v-b-tooltip.hover.bottom="'Cancelled'" v-if="data.item.status === 'Raised' || data.item.status === 'Paid'"><feather-icon size="21" icon="XCircleIcon" class="text-danger"/></span>
                      <span @click="markAsCreditNote(data.item)" v-b-tooltip.hover.bottom="'Credit Note'" v-if="data.item.status === 'Paid'"><feather-icon size="21" icon="CreditCardIcon" class="text-info"/></span>
                      <span @click="markAsRefunded(data.item)" v-b-tooltip.hover.bottom="'Refunded'" v-if="data.item.status === 'Paid'"><feather-icon size="21" icon="RepeatIcon" class="text-danger"/></span>
                      <span @click="markAsPaid(data.item)" v-b-tooltip.hover.bottom="'Paid'" v-if="data.item.status === 'Unpaid' || data.item.status === 'Raised'"><feather-icon size="21" icon="DollarSignIcon" class="text-success"/></span>
                    </div>
                  </template>
                  
                  </b-table>
                </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="center"
                      size="sm"
                      class="my-0"
                    />
                  </b-col>
            </b-row>
            </div>
              
        </b-card>    
    </div>
    <b-modal
      id="modal-invoice-update-status"
      ref="modal-invoice-update-status"
      :title="invoiceModalType"
      ok-title="submit"
      cancel-variant="outline-secondary"
      @ok="updateStatus()"
    >
            <b-form>
              <b-form-group label="Add Comment" label-for="vue-select" v-if="checkCommentAllowedCases()">
                <b-form-textarea
                  id="textarea-default"
                  v-model="invoiceComment"
                  placeholder="Add Comment for Refunded/Cancelled/Credit Note"
                  rows="3"
                />
              </b-form-group>
              <b-form-group v-if="invoiceModalType.includes('Email')"
                label="Email Date *"
                label-for="email-date"
              >     
                <flat-pickr
                  class="form-control"
                  id="email-date" 
                  v-model="invoiceEmailDate" 
                  placeholder="YYYY-MM-DD"
                />
              </b-form-group>
              <b-form-group v-if="invoiceModalType.includes('Paid')"
                label="Paid Date *"
                label-for="paid-date"
              >     
                <flat-pickr
                  class="form-control"
                  id="paid-date" 
                  v-model="invoicePaidDate" 
                  placeholder="YYYY-MM-DD"
                />
              </b-form-group>
            </b-form>
    </b-modal>        
   
  </section>
  </template>
  
  <script>
  import { BRow, BCol, BCard, BButton, BForm, BFormGroup,  BSpinner, BFormCheckbox, BFormCheckboxGroup, BTable, BBadge, BModal, VBModal, BFormTextarea, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BFormSelect, BFormInvalidFeedback, VBTooltip,BFormRadioGroup} from 'bootstrap-vue'
  import flatPickr from 'vue-flatpickr-component'
  import { FormWizard } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import vSelect from 'vue-select'

  import moment from 'moment'
  import UserService from '@/services/user-service'
  import clientService from '@/services/client-service'
  import invoiceService from '@/services/invoice-service'
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import adminConfigService from "@/services/admin-config-service";

  export default {
    data() {
      return {
        recruiters: '',
        recSelected: '',
        accountMgr: '',
        mgrSelected: '',
        sourceSelected: '',
        sources: [
            { title: "Naukri" },
            { title: "Monster" },
            { title: "ATS" },
            { title: "LinkedIn" },
            { title: "Indeed" },
            { title: "Shine" },
            { title: "TimesJobs" },
            { title: "IIMJobs" },
            { title: "Glassdoor" },
            { title: "FreshersWorld" },
            { title: "Other" },
            { title: "Train N Hire" },
            { title: "Internal" },
            { title: "Referenced" },
        ],
        categories: [
          { title: 'Recruitment'},
          { title: 'Training'},
          { title: 'Anti Fraud'},
          { title: 'Contractual'},
          { title: 'Payroll'},
          { title: 'BGV'},
          { title: 'Miscellanous'},
        ],
        clients: '',
        clientSelected: '',
        location: [{ title: 'Pune' }, { title: 'Delhi'}],
        locSelected: '',
        // startDate: '',
        // endDate: '',
        accessCheck: '',
        loading: false,
        //error: '',
        compiledData: [],
        isReadyToDownload: false,
        reportStatus: '',
        dateTypeOptions: [
          { text: 'Invoice Date', value: 'invoice_date' },
          { text: 'Invoice Paid Date', value: 'paid_date' },
          { text: 'Invoice Due Date', value: 'due_date' },
          { text: 'Invoice Email Date', value: 'email_date' }
        ],
        // invoiceStatusOptions: [
        //     { title: "Raised"},
        //     { title: "Paid"},
        //     { title: "Early Attrition"}
        // ],
        selected: [ "Raised", "Paid", "Unpaid", "Early Attrition", "Cancelled",null ],
        statusOptions: [
            { text: "Raised", value: "Raised"},
            { text: "Paid", value: "Paid"},
            { text: "Unpaid", value: "Unpaid"},
            { text: "Cancelled", value: "Cancelled"},
            { text: "Early Attrition", value: "Early Attrition"},
            { text: null, value: null}],
        fields: [
        { key: 'invoice_id', label: 'INVOICE ID', sortable: true},
        { key: 'candidateList', label: 'Item', sortable: true},
        //{ key: 'candidateList', label: 'Candidates', sortable: true},
        //{ key: 'position_name', label: 'Position', sortable: true},
        // { key: 'recruiter', label: 'Sourcer / Recruiter', sortable: true},
        { key: 'invoice_date', label: 'Invoice Date', sortable: true},
        { key: 'client', label: 'Client', sortable: true},
        { key: 'status', label: 'Status', sortable: true},
        { key: 'base_amount', label: 'Base Amount', sortable: true},
        { key: 'gst', label: 'GST', sortable: true},
        { key: 'total_amount', label: 'Total Amount', sortable: true},
        // { key: 'file', label: 'Attachment?', sortable: true},
        { key: "actions", label: 'Actions'},
      ],
      // invoiceStatusOptions: [
      //   { title: "Raised"},
      //   { title: "Paid"},
      //   { title: "Attrition"}
      // ],
      pageOptions: [10,20, 30, 50, 100, 200],
      totalRows: 0,
      perPage: 50,
      currentPage: 1,
      //error: '',
      sortBy: '',
      sortDesc: '',
      filter: null,
      sortDirection: 'asc',
      filterOn: [],
      filename: '',
      //interviewItems: [],
      invoiceStatusSelected: '',
      isSEZ: false,
      isIGST: false,
      checked: true,
      invoice_id: '',
      iid: 0,
      revenue: 0,
      ctc: 0,
      sgst: 0.0,
      cgst: 0.0,
      igst: 0.0,
      added_by: '',
      paid_date: '',
      early_attrition_date: '',
      invoiceModalType: '',
      invoiceEmailDate: null,
      invoiceComment: '',
      invoicePaidDate: null,
      invoiceStatus: null,
      emp_id: null,
      error: {
        revenue: '',
        ctc: '',
        invoice_id: '',
        igst: '',
        cgst: '',
        sgst: '',
        invoiceStatusSelected: '',
        early_attrition_date: '',
        paid_date: '',
        status: '',
        recs: [],
        all_ids : [],
        pendingInvoices: null
      },
      invoice: {},
      searchFilters: {
        status: '',
        startDate: '',
        endDate: '',
        client: '',
        category: '',
        filter: '',
        dateType: '',
      }
      }
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm, 
        BFormGroup,
        BSpinner,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormInvalidFeedback,
        // ExportExcelSheetVueJs,
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        ToastificationContent,
        BFormTextarea,
        BTable,
        BBadge,
        vSelect, BModal, VBModal, flatPickr,
        BPagination, BInputGroup, BFormInput, BInputGroupAppend, BFormSelect,
        AppCollapse,
        AppCollapseItem,
        StatisticCardHorizontal ,
        BFormRadioGroup
        //BMedia, BMediaAside, BAvatar, BMediaBody
  },
  directives: {
      'b-modal': VBModal,
      "b-tooltip": VBTooltip,
      Ripple,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    invoiceIdState() {
        return this.error.invoice_id = !this.invoice_id  ? 'Invoice Id is mandatory' : ''
    },
    revenueState() {
        return this.error.revenue = !this.revenue  ? 'Revenue is mandatory' : ''
    },
    paidState() {
        return this.error.paid_date = this.invoiceStatusSelected === 'Paid' && !this.paid_date ? 'Paid Date is Mandatory' : ''
    },
    attritionState() {
        return this.error.early_attrition_date = this.invoiceStatusSelected === 'Early Attrition' && !this.early_attrition_date ? 'Attrition Date is Mandatory' : ''
    }
    
    
  },
  watch: {
    revenue: function(newRevenue) {
        //let cgst = 0.0
        this.calculateGST();
        //return this.cgst
    },
    searchFilters: {
        handler(newFilters) {
          // Convert query parameters and new filters to strings for easy comparison
          const currentQuery = JSON.stringify(this.$route.query);
          const newQuery = JSON.stringify(newFilters);

          // Only navigate if the queries are different
          if (currentQuery !== newQuery) {
            this.$router.push({ query: newFilters }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
              // Ignore NavigationDuplicated error, but rethrow all other errors
            });
          }
        },
        deep: true,
      },
      $route(to) {
        this.searchFilters = { ...this.searchFilters, ...to.query };
      },
    searchFilters: {
        handler(newFilters) {
          // Convert query parameters and new filters to strings for easy comparison
          const currentQuery = JSON.stringify(this.$route.query);
          const newQuery = JSON.stringify(newFilters);

          // Only navigate if the queries are different
          if (currentQuery !== newQuery) {
            this.$router.push({ query: newFilters }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
              // Ignore NavigationDuplicated error, but rethrow all other errors
            });
          }
        },
        deep: true,
      },
      $route(to) {
        this.searchFilters = { ...this.searchFilters, ...to.query };
      },

  },
  created() {

    const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    const userEmpid = JSON.parse(localStorage.getItem('userData')).emp_id
    const userMgrid = JSON.parse(localStorage.getItem('userData')).mgr_id
    this.emp_id  = JSON.parse(localStorage.getItem('userData')).emp_id
    // this.accessCheck = userRole === 'ACCOUNT-MANAGER' || userRole === 'TALENT-ADVISOR' || userRole === 'TALENT-ADVISOR-SOURCER' ? false : true
    console.log(this.accessCheck)
    if(this.$can('read','invoice')) {

      if(this.$route.query) {
          //this.searchFilters = { ...this.searchFilters, ...this.$route.query };
          this.searchFilters.client = this.$route.query.client ? this.$route.query.client : ''
          this.searchFilters.status = this.$route.query.status ? this.$route.query.status : ''
          this.searchFilters.category = this.$route.query.category ? this.$route.query.category : ''
          this.searchFilters.dateType = this.$route.query.dateType ? this.$route.query.dateType : 'invoice_date'
          this.searchFilters.startDate = this.$route.query.startDate ? this.$route.query.startDate : ''
          this.searchFilters.endDate = this.$route.query.endDate ? this.$route.query.endDate : ''
          
        }  
    
    UserService.getAllUsersDdActiveInactive().then( res => {
        if(res.status === "OK") {
            console.log("in if")
            // if (userRole === 'account-manager') {
                
            //     this.recruiters = res.data.filter(item => (item.role === "talent-advisor-sourcer" || item.role === "account-manager" || item.role === "talent-advisor") && item.mgr_id === userEmpid);
            //     this.accountMgr = res.data.filter(item => item.role === "account-manager" && item.emp_id === userEmpid);
            //     console.log('AM', this.recruiters, this.accountMgr)
            // } else if (userRole === 'talent-advisor-sourcer' || userRole === 'talent-advisor') {
            //     this.recruiters = res.data.filter(item => (item.role === "talent-advisor-sourcer" || item.role === "account-manager" || item.role === "talent-advisor") && item.emp_id === userEmpid);
            //     this.accountMgr = res.data.filter(item => item.role === "account-manager" && item.mgr_id === userMgrid);
            //     console.log('REC', this.recruiters, this.accountMgr)
            // } else {
                this.recruiters = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager" || item.role === "talent-advisor");
                this.accountMgr = res.data.filter(item => item.role === "account-manager");
                console.log('ADMIN', this.recruiters, this.accountMgr)
            //}
            
        }
        
    })

    clientService.getClients().then(res => {
        this.clients = res.data
        console.log(this.clients)
    })
    
    this.getReport();
    } else {
    this.$router.push({ name: "misc-not-authorized" });
    }
    

  },
  methods: {
    getReport() {
      const emp_id  = JSON.parse(localStorage.getItem('userData')).emp_id
      const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
      const allUsers = JSON.parse(localStorage.getItem('allusers'))
      this.loading = true

      console.log(this.selected)
        
        const startDate = this.searchFilters.startDate ? this.searchFilters.startDate : '';
        const endDate = this.searchFilters.endDate ? this.searchFilters.endDate : '';
        const client = this.searchFilters.client ? this.searchFilters.client : '';
        const status = this.searchFilters.status ? this.searchFilters.status : '';
        const category = this.searchFilters.category ? this.searchFilters.category : '';
        const dateType = this.searchFilters.dateType ? this.searchFilters.dateType : '';
        
        
        


        invoiceService.getAll(startDate, endDate, status, client, category, dateType).then(res => {
            if (res.status === "OK") {
              this.reportStatus = ''
              this.compiledData = []
              //this.compiledData = res.data
              if (this.selected.length === 6) {
                this.compiledData = res.data.data
                this.statusOptions = res.data.statusList
                //this.pendingInvoices = res.data.pendingInvoices
                //this.compiledData = [...this.compiledData, ...this.pendingInvoices]
                this.totalRows = res.data.data.length
                //console.log('in max selected', this.pendingInvoices)
                this.calcInvoiceDashboard()
              } 
              
              this.isReadyToDownload = true
              console.log(res)
              //this.downloadReport = 'data:application/xlsx;base64,' + res.file
              this.filename = 'Invoice Tracker - ' + this.startDate + '_' + this.endDate + '.xls'
              
              this.loading = false

              if(this.compiledData.length === 0) {
                this.reportStatus = 'No Data found for selected dates & filter'
                this.isReadyToDownload = false
              }
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.reportStatus = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 400 ) ) {
              this.reportStatus = 'No Data Found for Selected Dates'
              this.loading = false
              this.compiledData = null
            }
            else if ((res.status === 500 ) ) {
              this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
          })

    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
    },
    resolveInvoiceStatusVariant(status) {
    
      
      if (status === 'Raised') return 'badge-info'
      if (status === 'Paid') return 'badge-success'
      if (status === 'Unpaid') return 'badge-warning'
      if (status === 'Cancelled') return 'badge-secondary'
      if (status === 'Early Attrition') return 'badge-danger'
      
      return 'badge-secondary'
    },
    createInvoice () {
        this.reportStatus = ''
        //validations
        if (!this.invoice_id || this.invoice_id === '') {
            this.error.invoice_id = 'Invoice creation Failed!! Invoice Id is mandatory'
            this.reportStatus = this.error.invoice_id
            return 
        }
        if (!this.revenue || this.revenue === 0.0) {
            this.error.revenue = 'Invoice creation Failed!! Revenue is mandatory'
            return 
        }

        const data = {
                    //note_title : this.note_title,
                    invoice_id: this.invoice_id.toUpperCase(),
                    candidate_id: this.candidate_id,
                    job_id: this.job_id,
                    status: 'Raised',
                    added_by: this.added_by,
                    ctc: this.ctc,
                    revenue: this.revenue,
                    cgst: this.isSEZ === true ? 0.0 : this.cgst,
                    sgst: this.isSEZ === true ? 0.0 : this.sgst,
                    igst: this.isSEZ === true ? 0.0 : this.igst,
                    isSEZ: this.isSEZ
                }
        invoiceService.create(data).then(res => {
            if (res.status === "OK") {
              //this.reportStatus = ''
                console.log(res)
                this.getReport()
              
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.reportStatus = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 402 ) ) {
              this.reportStatus = 'Invoice Id already exist, please use correct invoice id'
            }else if ((res.status === 400 ) ) {
              this.reportStatus = 'No Data Found for Selected Dates'
              
            }
            else if ((res.status === 500 ) ) {
              this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
              
            }
        })
    },
    updateInvoice() {
        this.reportStatus = ''
        //console.log('paid date', this.paid_date)
        if (!this.invoice_id || this.invoice_id === '') {
            this.error.invoice_id = 'Invoice Update Failed !!! Invoice Id is mandatory'
            this.reportStatus = this.error.invoice_id
            return 
        }

        if (!this.revenue || this.revenue === 0.0) {
            this.error.revenue = 'Invoice Update Failed !!! Revenue is mandatory'
            this.reportStatus = this.error.revenue
            return 
        }
        if (!this.paid_date && this.invoiceStatusSelected === 'Paid') {
            this.error.paid_date = 'Invoice Update Failed !!! Paid Date is mandatory'
            this.reportStatus = this.error.paid_date
            return 
        }
        if (!this.early_attrition_date && this.invoiceStatusSelected === 'Early Attrition') {
            this.error.early_attrition_date = 'Invoice Update Failed !!! Attrition Date is mandatory'
            this.reportStatus = this.error.early_attrition_date
            return 
        }
        if (!this.invoiceStatusSelected) {
            this.error.status = 'Invoice Update Failed !!! Status is mandatory'
            this.reportStatus = this.error.status
            return
        }

        const data = {
                    //note_title : this.note_title,
                    invoice_id: this.invoice_id.toUpperCase(),
                    candidate_id: this.candidate_id,
                    job_id: this.job_id,
                    status: this.invoiceStatusSelected,
                    added_by: this.added_by,
                    ctc: this.ctc,
                    revenue: this.revenue,
                    cgst: this.isSEZ === true ? 0.0 : this.cgst,
                    sgst: this.isSEZ === true ? 0.0 : this.sgst,
                    igst: this.isSEZ === true ? 0.0 : this.igst,
                    isSEZ: this.isSEZ,
                    paid_date: this.paid_date,
                    attrition_date: this.attrition_date
                }
        console.log(data)        

        invoiceService.update(this.iid,data).then(res => {
            if (res.status === "OK") {
              //this.reportStatus = ''
                console.log(res)
                this.getReport()
              
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.reportStatus = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 402 ) ) {
              this.reportStatus = 'Invoice Id already exist, please use correct invoice id'
            }else if ((res.status === 400 ) ) {
              this.reportStatus = 'No Data Found for Selected Dates'
              
            }
            else if ((res.status === 500 ) ) {
              this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
              
            }
        })
    },
    sendCandidateItemToModal(data) {
        console.log(data)
        // const emp_id  = JSON.parse(localStorage.getItem('userData')).emp_id
        this.iid = data.id
        this.invoice_id = data.invoice_id
        this.revenue = data.revenue
        this.ctc = data.ctc
        this.igst = data.igst
        this.cgst = data.cgst
        this.sgst = data.sgst
        this.isSEZ = data.isSEZ
        this.candidate_id = data.cid
        this.job_id = data.jid
        this.added_by = this.emp_id
        this.invoiceStatusSelected = data.status
        this.paid_date = data.paid_date
        this.early_attrition_date = data.early_attrition_date
    },
    calculateGST() {
        if(this.isSEZ) {
            this.cgst = 0.0
            this.sgst = 0.0
            this.igst = 0.0
        } else if (!this.isSEZ && !this.isIGST) {
            this.cgst = this.revenue * 0.09
            this.sgst = this.revenue * 0.09
            this.igst = 0.0
        } else if (!this.isSEZ && this.isIGST) {
            this.igst = this.revenue * 0.18
            this.cgst = 0.0
            this.sgst = 0.0
        }
    },
    markAsCreditNote(data) {
      this.invoiceModalType = 'Add Credit Note'
      this.invoiceStatus = 'CreditNote'
      this.$refs["modal-invoice-update-status"].show();
      
      this.invoice_id = data.invoice_id 

      

    },
    markAsEmailed(data) {
      this.invoiceModalType = 'Update Invoice Email Date'
      this.invoiceStatus = 'Unpaid'
      this.$refs["modal-invoice-update-status"].show();
      
      this.invoice_id = data.invoice_id
      // const dueDate = this.calcDueDate(data)

      

    },
    markAsRefunded(data) {
      this.invoiceModalType = 'Update Invoice as Refunded'
      this.invoiceStatus = 'Refunded'
      this.$refs["modal-invoice-update-status"].show();
      
      this.invoice_id = data.invoice_id

      

    },
    markAsCancelled(data) {
      this.invoiceModalType = 'Update Invoice as Cancelled'
      this.$refs["modal-invoice-update-status"].show();
      this.invoiceStatus = 'Cancelled'

      this.invoice_id = data.invoice_id

      

    },
    markAsPaid(data) {
      this.invoiceModalType = 'Update Invoice as Paid'
      
      this.$refs["modal-invoice-update-status"].show();
      this.invoiceStatus = 'Paid'

      this.invoice_id = data.invoice_id

      

    },
    updateStatus() {
      if (this.invoiceModalType === 'Update Invoice as Paid') {
        
        const updateInvoiceData = {
          status: this.invoiceStatus,
          comment: this.invoiceComment,
          updated_by: this.emp_id,
          paid_date: this.invoicePaidDate
        }

        invoiceService.updateInvoice(this.invoice_id, updateInvoiceData).then(res => {
          if (res.status === 'OK') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice marked As Paid!!! ",
                icon: "CheckCircleIcon",
                variant: "Success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice update Failed, Try again Later!!! ",
                icon: "XCircleIcon",
                variant: "danger",
              },
            });
          }
        })

      }
      if (this.invoiceModalType === 'Update Invoice as Cancelled') {
          
        const updateInvoiceData = {
          status: this.invoiceStatus,
          comment: this.invoiceComment,
          updated_by: this.emp_id
        }

        invoiceService.updateInvoice(this.invoice_id, updateInvoiceData).then(res => {
          if (res.status === 'OK') {

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice marked As Cancelled!!! ",
                icon: "CheckCircleIcon",
                variant: "Success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice update Failed, Try again Later!!! ",
                icon: "XCircleIcon",
                variant: "danger",
              },
            });
          }
        })
      }
      if (this.invoiceModalType === 'Update Invoice as Refunded') {
        
        const updateInvoiceData = {
          status: this.invoiceStatus,
          comment: this.invoiceComment,
          updated_by: this.emp_id
        }

        invoiceService.updateInvoice(this.invoice_id, updateInvoiceData).then(res => {
          if (res.status === 'OK') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice marked As Refunded!!! ",
                icon: "CheckCircleIcon",
                variant: "Success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice update Failed, Try again Later!!! ",
                icon: "XCircleIcon",
                variant: "danger",
              },
            });
          }
        })
      }
      if (this.invoiceModalType === 'Update Invoice Email Date') {
        const updateInvoiceData = {
          status: this.invoiceStatus,
          comment: this.invoiceComment,
          updated_by: this.emp_id,
          // due_date: dueDate
        }

        invoiceService.updateInvoice(this.invoice_id, updateInvoiceData).then(res => {
          if (res.status === 'OK') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice Credit Note Updated!!! ",
                icon: "CheckCircleIcon",
                variant: "Success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice update Failed, Try again Later!!! ",
                icon: "XCircleIcon",
                variant: "danger",
              },
            });
          }
        })
      }
      if (this.invoiceModalType === 'Add Credit Note') {
        const updateInvoiceData = {
          status: this.invoiceStatus,
          comment: this.invoiceComment,
          updated_by: this.emp_id
        }

        invoiceService.updateInvoice(this.invoice_id, updateInvoiceData).then(res => {
          if (res.status === 'OK') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice Credit Note Updated!!! ",
                icon: "CheckCircleIcon",
                variant: "Success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invoice update Failed, Try again Later!!! ",
                icon: "XCircleIcon",
                variant: "danger",
              },
            });
          }
        })
      }
    },
    calcInvoiceDashboard() {
      this.invoice = {
        raised: this.compiledData.filter(item => item.status === 'Raised' || item.status === 'Unpaid').length,
        raisedRevenue: this.compiledData.filter(item => item.status === 'Raised' || item.status === 'Unpaid').reduce((sum, item) => parseInt(sum) + parseInt(item.base_amount), 0),
        //pending: this.pendingInvoices.length,
        paid: this.compiledData.filter(item => item.status === 'Paid').length,
        paidRevenue: this.compiledData.filter(item => item.status === 'Paid').reduce((sum, item) => parseInt(sum) + parseInt(item.base_amount), 0),
        revenue: this.compiledData.reduce(function (acc, obj) { return parseInt(acc) + parseInt(obj.base_amount); }, 0),
        emailPending: this.compiledData.filter(item => item.status === 'Raised').length,
        overDue: this.calcDueDate()
      }
    },
    calcDueDate(data) {
      //console.log('Calculate Due Date based on client: ', data.item.client)
    },
    checkCommentAllowedCases() {

      if(this.invoiceModalType.includes('Refunded') || this.invoiceModalType.includes('Cancelled') || this.invoiceModalType.includes('Credit Note')) {
        return true;
      } else {
        return false;
      }
    },

    clearFilter() {
      this.searchFilters = {
        status: '',
        client: '',
        category: '',
        dateType: '',
        startDate: '',
        endDate: ''
      }
    },
    // showPendingInvoices() {
    //   this.compiledData = this.pendingInvoices
    // }
  },
  mounted() {
    adminConfigService.getATSDropdownList().then(res => {
      if(res.status === 200) {
        this.sources = res.data.data.sourceList
        // this.qualifications = res.data.data.qualificationList
      }
    })
  },
  updated: function () {
      console.log('called in updated')
      this.$nextTick(function () {
        console.log('called in updated nextTick')
        // Code that will run only after the
        // entire view has been re-rendered
        // let y = null
        const scrollPosition = localStorage.getItem('lastPosition')
        console.log('called in updated nextTick getting localstorage', typeof scrollPosition, scrollPosition)
        if (scrollPosition !== '[object Object]') {
           const y = JSON.parse(scrollPosition).y
           

          setTimeout( () => {
            console.log('called in updated nextTick after settimeout', scrollPosition, y)
            if(y) {
              window.scrollTo(0, y);
            }
          },500);
        }
        
        
      })
    },
}
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  <style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .demo-inline-spacing {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: inherit;
  }
  .demo-inline-spacing > * {
    margin-right: 0.5rem;
  }

  .demo-inline-spacing > * {
     margin-top: 0rem; 
  }
  </style>
  