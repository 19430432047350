<template>
  <div v-if="$can('read','candidates')">
    <!-- Alert: No item found -->
    <!--<b-alert
      variant="danger"
      :show="candidateData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching candidate data
      </h4>
      <div class="alert-body">
        No candidate found with this candidate id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-candidates-list'}"
        >
          Candidate List
        </b-link>
        for other candidates.
      </div>
    </b-alert>-->
    <ul class="breadcrumb" v-if="jobId">
      <li class="breadcrumb-item bitem"><a @click="$router.push({ name: 'dashboard-admin' })">Home</a></li>
      <li class="breadcrumb-item bitem"><a @click="$router.go(-2)">Jobs</a></li>
      <li class="breadcrumb-item bitem"><a @click="$router.push({ path: '/apps/view-job/' + jobId + '?savedPosition=' + savedPosition })">View Job</a></li>
      <li class="breadcrumb-item">Candidate View</li>
    </ul>
    <ul class="breadcrumb" v-else>
      <li class="breadcrumb-item bitem"><a @click="$router.push({name: 'dashboard-admin'})">Home</a></li>
      <li class="breadcrumb-item bitem"><a @click="$router.go(-1)">Candidates</a></li>
      <li class="breadcrumb-item">Candidate View</li>
    </ul>
    <div v-if="candidateData">
      <!-- <div v-if="error"> {{ error }}</div> -->
      <b-card>
        <b-row>
          <b-col cols="4" xl="4" md="4">
            <span class="text-danger" style="font-weight: 600; font-size: 14px">{{ error }}</span>
            <h4 style="padding-top: 12px">
            {{ candidateData.candidate_id }} - {{ candidateData.first_name}}  {{ candidateData.last_name}} <span v-if="candidateData.curr_position">    - ({{ candidateData.curr_position }})</span>
            </h4>
          </b-col>
          <b-col cols="8" xl="8" md="8" style="text-align: right;" >
              <!--{{ candidateData.jobs.filter(item => item.id == jobId)[0].candidate_jobs.candidateStatus }}-->
            <div class="demo-inline-spacing" style="float: right;" v-if="$can('update','candidate_status')">
                <div v-if="intNotIntNotRelVisible">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    :to="{
                              name: 'apps-candidates-edit', 
                              params: { id: candidateData.id, jobId: jobId },
                              query: {  position_name: position_name,
                                        client: client,
                                        attachments: attachments,
                                        rec_name: rec_name,
                                        locations: locations,
                                        position_type: positionType,
                                        vertical: vertical,
                                        category: category,
                                        payroll: payroll,
                                        // min_ctc: jobData.min_ctc,
                                        // max_ctc: jobData.max_ctc,
                                        // min_exp: jobData.min_exp,
                                        // max_exp: jobData.max_exp,
                                        // notice_period: jobData.notice_period,
                                        interested: 'YES'
                                }
                            }"
                  >
                    Interested
                  </b-button>
                </div>
                <div v-if="intNotIntNotRelVisible">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning"
                    @click="showNINRModal('ni')"
                  >
                    Not Interested
                  </b-button>
                </div>
                <div v-if="intNotIntNotRelVisible">  
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="showNINRModal('nr')"
                  >
                    Not Relevant
                  </b-button>
                </div>
                <div v-if="intNotIntNotRelVisible">  
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning"
                    @click="showDupModal()"
                  >
                    Duplicate
                  </b-button>
                </div>
                <div v-if="intNotIntNotRelVisible">  
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    @click="showNotAnsweredModal()"
                  >
                    Call Related
                  </b-button>
                </div>
                <!-- <div>
                  <h6 class="mb-0 text-info"  v-if=" positionType === 'Contractual' && category === 'Blue Collar' && vertical === 'Infra Industry' && payroll === 'Sattva Payroll' && (prevStatus === 'Shortlisted' || prevStatus === 'Document Submitted' || prevStatus === 'Offered'  || prevStatus === 'Joined' || prevStatus === 'Attrition' )">
                    <button @click="showGenerateOfferModal(candidateData.id)" class="btn btn-outline-info">Generate Offer Letter </button> 
                  </h6>
                </div> -->
                <div>
                  <h6 class="mb-0 text-info"  v-if=" positionType === 'Contractual' && category === 'Blue Collar' && vertical === 'Infra Industry' && payroll === 'Sattva Payroll' && (prevStatus === 'Confirmed' || prevStatus === 'ProfileShared' || prevStatus === 'Document Submitted'  || prevStatus === 'Interview' || prevStatus === 'Shortlisted' || prevStatus === 'Offered' || prevStatus === 'Drop' || prevStatus === 'Joined' || prevStatus === 'Attrition')">
                    <button @click="showDocuploadModal(candidateData.id)" class="btn btn-outline-info">Upload/View Documents  </button> 
                  </h6>
                </div>
                <div  v-if="jobId && candidateJobs && candidateData.curr_position && candidateData.qualification">
                  <button :disabled="pausedFor7Seconds"
                    id="update-can" class="btn btn-outline-primary" style="color: green !important" 
                    @click="showCModal(candidateData.id, candidateData.status )"
                    v-if="updateButtonVisible && $can('update','candidate_status')"
                    >
                    Update Status
                  </button>
                  <!--<button :disabled="pausedFor7Seconds" 
                    id="update-can" class="btn btn-outline-primary" style="color: green !important" 
                    @click="showCModal(candidateData.id, candidateData.status )"
                    v-if="userRole === 'ACCOUNT-MANAGER' || userRole === 'ADMIN' || userRole === 'MASTER'"
                    >
                    Update Status
                  </button>
                  <button :disabled="pausedFor7Seconds" 
                    id="update-can" class="btn btn-outline-primary" style="color: green !important" 
                    @click="showCModal(candidateData.id, candidateData.status )"
                    v-else-if="userRole === 'TALENT-ADVISOR' && (candidateData.jobs[0].candidate_jobs.candidateStatus !== 'Initiated' && candidateData.jobs.filter(item => item.id === jobId && item.candidate_jobs.job_id === jobId && JSON.parse(item.candidate_jobs.addedBy)[0].emp_id === emp_id)).length > 0"
                    >
                    Update Status
                  </button>-->
                </div>
              <!--<li style="display: inline-flex; margin: 5px; padding: 2px" @click="downloadFile(candidateData.cv_path)">-->
              
                <div>
                  <h6 class="mb-0" style="color: #00AC99" v-if="cvPresent">
                    <a :href="dlink">Download CV <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></a>
                  </h6>
                  <h6 class="mb-0" style="color: red" v-else>
                    CV Not Present
                  </h6>
                    
                  
                </div>
            </div>
          </b-col>
        </b-row>
        
      </b-card>
      <!--<b-card-actions  action-collapse>-->
      <b-row v-if="showCandidateDetails">
        <b-col cols="8" xl="8">
          <b-card>
            <b-row>
                  
                  <b-col md="4" xl="4" xs="6" class="mb-1">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Email </span>
                        <h6 class="mb-0">
                          <span>{{ candidateData.email }} <img :src="require('@/assets/images/icons/copy.png')" width="18px" height="18px" @click="doCopy(candidateData.email)"></span>
                        </h6>
                        
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Contact No </span>
                        <h6 class="mb-0">
                          {{ candidateData.contact_no }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.curr_location">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Location </span>
                        <h6 class="mb-0">
                          {{ candidateData.curr_location }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.pref_location">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Preferred Location </span>
                        <h6 class="mb-0">
                          {{ candidateData.pref_location }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.curr_company">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Company </span>
                        <h6 class="mb-0">
                          {{ candidateData.curr_company }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  
                  <b-col md="4" xl="4" xs="6" class="mb-1">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Skills </span>
                        <h6 class="mb-0">
                          {{ candidateData.skills }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Sourced From </span>
                        <h6 class="mb-0">
                          {{ candidateData.source }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.qualification">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Qualification </span>
                        <h6 class="mb-0">
                          {{ candidateData.qualification }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.exp">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Experience </span>
                        <h6 class="mb-0">{{ candidateData.exp }} <span v-if="candidateData.relevant_exp"> ( Relevant: {{ candidateData.relevant_exp }}) </span> Years</h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.ctc">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> CTC </span>
                        <h6 class="mb-0">₹ {{ candidateData.ctc }} <span v-if="!blueCollar">Lacs</span></h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.exp_ctc">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Expected CTC </span>
                        <h6 class="mb-0">₹ {{ candidateData.exp_ctc }} <span v-if="!blueCollar">Lacs</span></h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.notice_period">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Notice Period </span>
                        <h6 class="mb-0">
                          {{ candidateData.notice_period }} Days
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.gender">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Gender </span>
                        <h6 class="mb-0">
                          {{ candidateData.gender }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.dob">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> DOB </span>
                        <h6 class="mb-0">
                          {{ candidateData.dob === "1900-01-01" ? '-    ' : candidateData.dob }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <!--<b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.status">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Status </span>
                        <h6 class="mb-0">
                          {{ prevStatus }}
                        </h6>
                      </div>
                    </div>
                  </b-col>-->
                  <b-col md="4" xl="4" xs="6" class="mb-1">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Added on </span>
                        <h6 class="mb-0">
                          {{ formatDate }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Added by </span>
                        <h6 class="mb-0" v-if="candidateData.created_by">
                          {{ JSON.parse(candidateData.created_by)[0].title }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Updated by </span>
                        <h6 class="mb-0" v-if="candidateData.updated_by">
                          {{ JSON.parse(candidateData.updated_by)[0].title }}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4" xl="4" xs="6" class="mb-1" v-if="candidateData.remarks">
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <span class="text-muted"> Remarks </span>
                        <h6 class="mb-0">
                          {{ candidateData.remarks}}
                        </h6>
                      </div>
                    </div>
                  </b-col>
                  
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4" xl="4">
          <b-card title="Candidate Timeline">
            <app-timeline v-for="timeline in candidateJobs" :key="timeline.id">
              <!-- <app-timeline-item v-if="timeline.candidateStatus !== 'Not Interested' && timeline.candidateStatus !== 'Not Relevant' && timeline.candidateStatus !== 'Call Back'"
                :variant="timeline.type"
                :title="timeline.job_id + ' - ' + timeline.position_name"
                :subtitle="'Current Status in Job: ' + timeline.candidateStatus + '  Source: ' + timeline.source"
                :time="timeline.createdAt + '  by  '  + JSON.parse(timeline.addedBy)[0].title"
              /> -->
              <app-timeline-item v-if="timeline.candidateStatus === 'Not Interested'"
                :variant="timeline.type"
                :title="timeline.job_id + ' - ' + timeline.position_name"
                :subtitle="'Current Status in Job: ' + timeline.candidateStatus + '  Source: ' + timeline.source + '  Reason: ' + timeline.reason + '  Comment: ' + timeline.comment"
                :time="timeline.createdAt + '  by  '  + timeline.rec_name"
              />
              <app-timeline-item v-if="timeline.candidateStatus === 'Not Relevant' || timeline.candidateStatus === 'Call Back'"
                :variant="timeline.type"
                :title="timeline.job_id + ' - ' + timeline.position_name"
                :subtitle="'Current Status in Job: ' + timeline.candidateStatus + '  Source: ' + timeline.source + '  Comment: ' + timeline.comment"
                :time="timeline.createdAt + '  by  '  + timeline.rec_name"
              />
              <app-timeline-item variant="success" v-if="timeline.candidateStatus !== 'Not Interested' && timeline.candidateStatus !== 'Not Relevant' && timeline.candidateStatus !== 'Call Back'">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>{{ timeline.job_id + ' - ' + timeline.position_name }}</h6>
                  <small class="text-muted">{{ timeline.createdAt }}</small>
                </div>
                <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                  <!-- 1st Col -->
                  <div class="mb-1 mb-sm-0">
                    <span class="text-muted mb-50">S/R</span>

                    <div>
                      <b-avatar v-if="timeline.sourcer_name"
                        v-b-tooltip.hover
                        :title="'Sourcer: ' + timeline.sourcer_name" 
                        class="mr-50"
                        size="24"
                        :text="avatarText(timeline.sourcer_name)"
                        variant="light-primary"
                      />
                      <b-avatar v-if="timeline.rec_name"
                        v-b-tooltip.hover
                        :title="'Recruiter: ' + timeline.rec_name" 
                        class="mr-50"
                        size="24"
                        :text="avatarText(timeline.rec_name)"
                        variant="light-success"
                      />
                      
                    </div>
                  </div>
                  <!-- 2nd Col -->
                  <div class="mb-1 mb-sm-0">
                    <span class="text-muted mb-50 d-block">Status</span>
                    <span>{{ timeline.candidateStatus }}</span>
                  </div>
                  <!-- 3rd Col -->
                  <div>
                    <span class="text-muted mb-50 d-block">Source</span>
                    <span>{{ timeline.source }}</span>
                  </div>
                </div>
                <div v-if="timeline.selection_date || timeline.offered_date || timeline.joining_date" class="mt-1 d-flex flex-sm-row flex-column justify-content-between align-items-start">
                  <!-- 1st Col -->
                  <div class="mb-1 mb-sm-0" v-if="category !== 'Blue Collar'">
                    <span class="text-muted mb-50 d-block">Selected On</span>
                    <span>{{ timeline.selection_date }}</span>
                  </div>
                  <!-- 2nd Col -->
                  <div class="mb-1 mb-sm-0" v-if="category !== 'Blue Collar'">
                    <span class="text-muted mb-50 d-block">Offered On</span>
                    <span>{{ timeline.offered_date }}</span>
                  </div>
                  <!-- 3rd Col -->
                  <div>
                    <span class="text-muted mb-50 d-block">DOJ</span>
                    <span>{{ timeline.joining_date }}</span>
                  </div>
                  <div class="mb-1 mb-sm-0" v-if="category === 'Blue Collar'">
                    <span class="text-muted mb-50 d-block">LOI Issued</span>
                    <span>{{ timeline.loi_issued ? 'YES' : 'NO' }}</span>
                  </div>
                </div>
                <div v-if="timeline.drop_reason || timeline.revenue || timeline.offered_ctc" class="mt-1 d-flex flex-sm-row flex-column justify-content-between align-items-start">
                  <!-- 1st Col -->
                  <div class="mb-1 mb-sm-0" v-if="timeline.drop_reason">
                    <span class="text-muted mb-50 d-block">Reason</span>
                    <span>{{ timeline.drop_reason }}</span>
                  </div>
                  <!-- 2nd Col -->
                  <div class="mb-1 mb-sm-0" v-if="timeline.revenue || timeline.offered_ctc">
                    <span class="text-muted mb-50 d-block">Offered CTC vs Revenue</span>
                    <span>{{timeline.offered_ctc + ' - ' + timeline.revenue }}</span>
                  </div>
                  <!-- 3rd Col -->
                  <div v-if="timeline.revenue || timeline.offered_ctc || timeline.candidateStatus === 'Joined' || timeline.candidateStatus === 'Offered'">
                    <span class="text-muted mb-50 d-block">Revenue Confirmed</span>
                    <span>{{ timeline.revenue_confimed ? timeline.revenue_confimed : false }}</span>
                  </div>
                </div>
                </app-timeline-item>
          </app-timeline>
  </b-card>
        </b-col>    
      </b-row>
        
      <!--</b-card-actions>-->
      <b-modal
        id="modal-cupdate-status"
        ref="modal-cupdate-status"
        title="Update Candidate Status"
        ok-title="submit"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        @ok="updateCandidateStatus"
      >
        <b-form @submit.prevent>
          
          <!-- <b-form-group label="Choose the Status" label-for="vue-select" v-if="jobBySourcer === 'sourcer'"> -->
          <b-form-group label="Choose the Status" label-for="vue-select">
            <li 
              style="display: inline-flex; margin: 5px; padding: 2px"
              v-for="status in cStatusOption"
              :key="status.text"
            >
              <b-badge
                style="color: black !important"
                variant="light"
                :id="status.text"
                @click="selectCStatus(status.text)"
                >{{ status.value }}</b-badge
              >
            </li>
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Shortlisted'"
            label="Selection Date *"
            label-for="selectedDate"
          >
            <flat-pickr
              class="form-control"
              id="selDate" 
              v-model="selectionDate" 
              placeholder="YYYY-MM-DD"
              :config="flatpickrConfig"
              
            />
          </b-form-group>
          
          <b-form-group v-if="statusCSelected === 'Offered'"
            label="Offered Date *"
            label-for="offeredDate"
          >
            <flat-pickr
              class="form-control"
              id="offerDate" 
              v-model="offeredDate" 
              placeholder="YYYY-MM-DD"
              :config="flatpickrConfig"
              
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Offered'"
            label="Joining Date"
            label-for="joiningDate"
          >     
            <flat-pickr
              class="form-control"
              id="joinDate" 
              v-model="joiningDate" 
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Offered' && client !== 'Sattva Human Pvt Ltd'"
            label="Fixed CTC"
            label-for="offeredCTC"
          >     
            <b-form-input
              class="form-control"
              id="offeredCTC" 
              v-model="offeredCTC" 
              placeholder="Enter Fixed CTC in Rupees(complete amount like 10000.00)"
              type="number"
              step=".01"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Offered' && client !== 'Sattva Human Pvt Ltd' && !blueCollar"
            label="Variable CTC"
            label-for="variableCTC"
          >     
            <b-form-input
              class="form-control"
              id="variableCTC" 
              v-model="variableCTC" 
              placeholder="Enter Variable CTC in Rupees(complete amount like 10000.00)"
              type="number"
              step=".01"
            />
          </b-form-group>
          <!-- <b-form-group v-if="statusCSelected === 'Offered' && client !== 'Sattva Human Pvt Ltd'"
            label="Band"
            label-for="band"
          >     
            <b-form-input
              class="form-control"
              id="band" 
              v-model="band" 
              placeholder="Enter Employee Band as per Offer Letter"
            />
          </b-form-group> -->
          <b-form-group v-if="statusCSelected === 'Joined'"
            label="Joining Date *"
            label-for="joiningDate"
          >     
            <flat-pickr
              class="form-control"
              id="joinDate" 
              v-model="joiningDate" 
              placeholder="YYYY-MM-DD"
              @input="setContractStartDate"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Joined' && (positionType === 'Contractual' || positionType === 'Cost Plus') && payroll === 'Sattva Payroll' && category === 'White Collar'"
            label="Contract Start Date *"
            label-for="contractStartDate"
          >     
            <flat-pickr
              class="form-control"
              id="contractStartDate" 
              v-model="contractStartDate" 
              placeholder="YYYY-MM-DD"
              
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Joined' && (positionType === 'Contractual' || positionType === 'Cost Plus') && payroll === 'Sattva Payroll' && category === 'White Collar'"
            label="Contract End Date *"
            label-for="contractStartDate"
          >     
            <flat-pickr
              class="form-control"
              id="contractEndDate" 
              v-model="contractEndDate" 
              placeholder="YYYY-MM-DD"
              
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Joined' && (positionType === 'Contractual' || positionType === 'Cost Plus') && payroll === 'Sattva Payroll' && category === 'White Collar'"
            label="Rate Card Without GST*"
            label-for="rateCard"
          >     
            <b-form-input
              class="form-control"
              id="rateCard" 
              v-model="rateCard" 
              placeholder="Enter Rate Card of employee"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Joined' && (positionType === 'Contractual' || positionType === 'Cost Plus') && payroll === 'Sattva Payroll' && category === 'White Collar'"
            label="Gross Monthly Salary *"
            label-for="monthlyCTC"
          >     
            <b-form-input
              class="form-control"
              id="monthlyCTC" 
              v-model="monthlyCTC" 
              placeholder="Enter Gross Monthly Salary of employee"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Joined' && (positionType === 'Contractual' || positionType === 'Cost Plus') && payroll === 'Sattva Payroll' && category === 'White Collar'"
            label="Probation Days *"
            label-for="probationDays"
          >     
            <b-form-input
              class="form-control"
              id="probationDays" 
              v-model="probationDays" 
              placeholder="Enter Probation Days of employee"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'NotOffered'"
            label="Drop Reason *"
            label-for="drop-reason"
          >
            <v-select
                      v-model="dropReason"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="notOfferedOptions"
                      ref="drop-reason"
                      :reduce="(option) => option.title"
                    />
                    <!-- taggable
                      push-tags -->
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Early Attrition' || statusCSelected === 'Attrition'"
            label="Attrition Date *"
            label-for="attritionDate"
          >     
            <flat-pickr
              class="form-control"
              id="attritionDate" 
              v-model="attritionDate" 
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'OfferDrop'"
            label="Drop Reason *"
            label-for="drop-reason"
          >
            <v-select
                      v-model="dropReason"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="offerDropOptions"
                      ref="drop-reason"
                      :reduce="(option) => option.title"
                      taggable
                      push-tags
                    />
          </b-form-group>                    
          <small v-if="!statusCSelected" class="text-danger"> {{ error_status }}</small>
          
        </b-form>
        <b-form v-if="statusCSelected === 'Duplicate'">
          <b-form-group label="Choose the Reason *" label-for="vue-select"  v-if="NI">
            
            <v-select
                      v-model="dupReasonSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="DuplicateOptions"
                      ref="dup-reaon"
                    />
          </b-form-group>
          <b-form-group label="Add Cooling Period if avaiable *" label-for="vue-select">
            <v-select
                      v-model="coolingTimeline"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="coolingTimelineOptions"
                      ref="cooling-option"
                    />
                <small v-if="!NI_NR_comment" class="text-danger"> {{ error_NI_NR_comment }}</small>
              </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-ni-nr-reason"
        ref="modal-ni-nr-reason"
        no-close-on-backdrop
        :title="NI === true ? 'Select Reason & Add Comment for Not Interested Candidate': 'Add Comment for Not Relevant Candidate'"
        ok-title="submit"
        cancel-variant="outline-secondary"
        @ok="updateCandidateNINRReason"
      >
        <b-form>
          <b-form-group label="Choose the Reason *" label-for="vue-select"  v-if="NI">
            
            <v-select
                      v-model="NI_Reason_Selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="NotInterestedReason"
                      ref="ni-reaon"
                    />
          </b-form-group>
          <b-form-group label="Add Comment *" label-for="vue-select">
                <b-form-textarea
                  id="textarea-default"
                  v-model="NI_NR_comment"
                  placeholder="add comment for not interested, not relevant candidate"
                  rows="3"
                />
                <small v-if="!NI_NR_comment" class="text-danger"> {{ error_NI_NR_comment }}</small>
              </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-dup-status"
        ref="modal-dup-status"
        no-close-on-backdrop
        title="Select Reason & Add Timeline for Duplicate Candidate"
        ok-title="submit"
        cancel-variant="outline-secondary"
        @ok="updateDuplicate"
      >
        <b-form>
          <b-form-group label="Choose the Reason *" label-for="vue-select"  >
            
            <v-select
                      v-model="dupReasonSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="DuplicateOptions"
                      ref="dup-reaon"
                    />
          </b-form-group>
          <b-form-group label="Add Cooling Period if avaiable *" label-for="vue-select">
            <v-select
                      v-model="coolingTimeline"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="coolingTimelineOptions"
                      ref="cooling-option"
                    />
                <small v-if="!NI_NR_comment" class="text-danger"> {{ error_NI_NR_comment }}</small>
              </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-na-status"
        ref="modal-na-status"
        no-close-on-backdrop
        title="Select Proper Status for Candidate"
        ok-title="submit"
        cancel-variant="outline-secondary"
        @ok="updateNotAnswered"
      >
        <b-form>
          <b-form-group label="Choose the Reason *" label-for="vue-select">
            
            <v-select
                      v-model="NA_Reason_Selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="NotAnsweredOptions"
                      ref="na-reason"
                    />
          </b-form-group>
          <b-form-group label="Add Comment" label-for="vue-select">
                <b-form-textarea
                  id="textarea-default"
                  v-model="NA_comment"
                  placeholder="add comment for Call Back Candidates"
                  rows="3"
                />
                <small v-if="!NA_comment" class="text-danger"> {{ error_NA_comment }}</small>
              </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-doc-upload"
        ref="modal-doc-upload"
        no-close-on-backdrop
        title="Upload Documents"
        size="lg"
        ok-only
        ok-title="OK"
      >
      
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6" v-if="showAadhaarUpload">
                <b-form-group label="Aadhaar Card" class="text-primary">
                  <b-form-file
                    v-model="documents.aadhaarCard"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    id="aadhaar-file"
                    name="aadhaar-file"
                    accept="application/pdf,image/png,image/jpeg,image/jpg"
                    
                  />
                  <b-card-text class="my-1">
                    Selected file: <strong>{{ documents.aadharCard ? documents.aadharCard.name : '' }}</strong>
                  </b-card-text>
              </b-form-group>
            </b-col>
            <b-col md="4" v-else>
              <h4 class="my-1 mt-2 pt-1 text-primary">
              <strong><u>Aaadhar File</u></strong>
              </h4>
            </b-col>
            <b-col col class="mt-2 pt-1" v-if="showAadhaarUpload">
              <span class="text-primary" @click="uploadAadhaar"> Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
            
            <b-col col class="mt-2 pt-1" v-if="aadhaarUploaded">
              <span class="text-primary" @click="previewFile('aadhaar')"> Preview <feather-icon icon="EyeIcon" size="18" class="mr-50" /></span>
              <span class="text-primary" @click="downloadFile('aadhaar')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></span>
              <!-- <a :href="docDownloadlink" @click="downloadFile('aadhaar')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></a> -->
            </b-col>
          
            <b-col col class="mt-2 pt-1" v-if="aadhaarUploaded">
              <span class="text-warning" @click="reUploadAadhaar" v-if="!showAadhaarUpload"> Re-Upload File <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
              <span class="text-warning" @click="reUploadAadhaar" v-if="showAadhaarUpload"> Cancel Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
            
          </b-row>
          <hr style="border: 1px solid lightgreen;">
        </b-form> 
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6" v-if="showPanUpload">
                <b-form-group label="Pan Card" class="text-primary">
                  <b-form-file
                    v-model="documents.panCard"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    id="pan-file"
                    name="pan-file"
                    accept="application/pdf,image/png,image/jpeg,image/jpg"
                  />
                  <b-card-text class="my-1">
                    Selected file: <strong>{{ documents.panCard ? documents.panCard.name : '' }}</strong>
                  </b-card-text>
              </b-form-group>
            </b-col>
            <b-col md="4" v-else>
              <h4 class="my-1 mt-2 pt-1 text-primary">
              <strong><u>Pan Card File</u></strong>
              </h4>
            </b-col>
            <b-col col class="mt-2 pt-1" v-if="showPanUpload">
              
              <span class="text-primary" @click="uploadPan"> Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
            
            <b-col col class="mt-2 pt-1" v-if="panUploaded">
              <span class="text-primary" @click="previewFile('pan')"> Preview <feather-icon icon="EyeIcon" size="18" class="mr-50" /></span>
              <span class="text-primary" @click="downloadFile('pan')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></span>
              <!-- <a :href="docDownloadlink" @click="downloadFile('pan')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></a> -->
            </b-col>
          
            <b-col col class="mt-2 pt-1" v-if="panUploaded">
              <span class="text-warning" @click="reUploadPan" v-if="!showPanUpload"> Re-Upload File <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
              <span class="text-warning" @click="reUploadPan" v-if="showPanUpload"> Cancel Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
          </b-row>
          <hr style="border: 1px solid lightgreen;">
        </b-form>
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6" v-if="showPhotoUpload">
                <b-form-group label="Passport Size Photo" class="text-primary">
                  <b-form-file
                    v-model="documents.passportPhoto"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    id="photo-file"
                    name="photo-file"
                    accept="application/pdf,image/png,image/jpeg,image/jpg"
                  />
                  <b-card-text class="my-1">
                    Selected file: <strong>{{ documents.passportPhoto ? documents.passportPhoto.name : '' }}</strong>
                  </b-card-text>
              </b-form-group>
            </b-col>
            <b-col md="4" v-else>
              <h4 class="my-1 mt-2 pt-1 text-primary">
              <strong><u>Passport Size Photo</u></strong>
              </h4>
            </b-col>
            <b-col col class="mt-2 pt-1" v-if="showPhotoUpload">
              
              <span class="text-primary" @click="uploadPhoto"> Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
            
            <b-col col class="mt-2 pt-1" v-if="photoUploaded">
              <span class="text-primary" @click="previewFile('photo')"> Preview <feather-icon icon="EyeIcon" size="18" class="mr-50" /></span>
              <span class="text-primary" @click="downloadFile('photo')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></span>
              <!-- <a :href="docDownloadlink" @click="downloadFile('photo')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></a> -->
            </b-col>
          
            <b-col col class="mt-2 pt-1" v-if="photoUploaded">
              <span class="text-warning" @click="reUploadPhoto" v-if="!showPhotoUpload"> Re-Upload File <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
              <span class="text-warning" @click="reUploadPhoto" v-if="showPhotoUpload"> Cancel Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
          </b-row>
          <hr style="border: 1px solid lightgreen;">
        </b-form>
        
        <b-form @submit.prevent v-if="prevStatus === 'Interview' || prevStatus === 'Shortlisted' || prevStatus === 'Offered' || prevStatus === 'Joined' || prevStatus === 'LOI Issued' || prevStatus === 'Attrition' || prevStatus === 'Early Attrition' || prevStatus === 'Drop'">
          <b-row>
            <b-col md="6" v-if="showBankUpload">
                <b-form-group label="Bank Details Proof Document" class="text-primary">
                  <b-form-file
                    v-model="documents.bankProof"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    id="bank-file"
                    name="bank-file"
                    accept="application/pdf,image/png,image/jpeg,image/jpg"
                  />
                  <b-card-text class="my-1">
                    Selected file: <strong>{{ documents.bankProof ? documents.bankProof.name : '' }}</strong>
                  </b-card-text>
              </b-form-group>
            </b-col>
            <b-col md="4" v-else>
              <h4 class="my-1 mt-2 pt-1 text-primary">
              <strong><u>Bank Details Proof Document</u></strong>
              </h4>
            </b-col>
            <b-col col class="mt-2 pt-1" v-if="showBankUpload">
              
              <span class="text-primary" @click="uploadBank"> Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
            <b-col col class="mt-2 pt-1" v-if="bankUploaded">
              <span class="text-primary" @click="previewFile('bankProof')"> Preview <feather-icon icon="EyeIcon" size="18" class="mr-50" /></span>
              <span class="text-primary" @click="downloadFile('bankProof')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></span>
              
            </b-col>
          
            <b-col col class="mt-2 pt-1" v-if="bankUploaded">
              <span class="text-warning" @click="reUploadBank" v-if="!showBankUpload"> Re-Upload File <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
              <span class="text-warning" @click="reUploadBank" v-if="showBankUpload"> Cancel Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
          </b-row>
          <hr style="border: 1px solid lightgreen;">
        </b-form>
        <b-form @submit.prevent>
          
          <b-row>
            
            <b-col md="6" v-if="showCertificateUpload && skillCertificateAvailable">
                <b-form-group label="Skill Certificate" class="text-primary">
                  
                  <b-form-file
                    v-model="documents.skillCertificate"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    id="certificate-file"
                    name="certificate-file"
                    accept="application/pdf,image/png,image/jpeg,image/jpg"
                  />
                  <span></span>
                  <b-card-text class="my-1">
                    Selected file: <strong>{{ documents.skillCertificate ? documents.skillCertificate.name : '' }}</strong>
                  </b-card-text>
                </b-form-group>
            </b-col>
            <b-col md="4" v-else>
              <h4 class="my-1 mt-2 pt-1 text-primary">
              <strong><u>Skill Certificate</u></strong>
              </h4>
            </b-col>
            <b-col col class="mt-2 pt-1" v-if="showCertificateUpload && skillCertificateAvailable">
              
              <span class="text-primary" @click="uploadCertificate"> Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
            
            <b-col col class="mt-2 pt-1" v-if="certificateUploaded">
              <span class="text-primary" @click="previewFile('skillCertificate')"> Preview <feather-icon icon="EyeIcon" size="18" class="mr-50" /></span>
              <span class="text-primary" @click="downloadFile('skillCertificate')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></span>
              <!-- <a :href="docDownloadlink" @click="downloadFile('skillCertificate')"> Download <feather-icon icon="ArrowDownCircleIcon" size="18" class="mr-50" /></a> -->
            </b-col>
          
            <b-col col class="mt-2 pt-1" v-if="certificateUploaded">
              <span class="text-warning" @click="reUploadCertificate" v-if="!showCertificateUpload"> Re-Upload File <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
              <span class="text-warning" @click="reUploadCertificate" v-if="showCertificateUpload"> Cancel Upload <feather-icon icon="ArrowUpCircleIcon" size="18" class="mr-50" /></span>
            </b-col>
          
          
            <b-col col class="mt-2 pt-1" v-if="!certificateUploaded">
              <div style="float:left" >
                <b-card-text class="mb-1">
                  Is Skill Certificate Available?
                </b-card-text>
                  <b-form-checkbox v-model="skillCertificateAvailable" name="check-button" switch></b-form-checkbox>
              </div>
            </b-col>
          
          </b-row>
          <hr style="border: 1px solid lightgreen;">
        </b-form> 
          
      </b-modal>
      <!-- <b-modal
        id="modal-generate-offer-letter"
        ref="modal-generate-offer-letter"
        @ok="previewOfferLetter"
        size="xl"
        title="Offer Letter"
        
      >
      <b-form>
        <b-row>
            <b-col
                cols="12"
                class="mb-2"
              >
                <quill-editor
                  v-model="offerLetterContent"
                  :options="snowOption"
                  ref="jd"

                />
                <small class="text-danger">{{ error.jd }}</small>
            </b-col>
        </b-row>
      </b-form>
      
      </b-modal> -->
      <b-modal
            id="doc-preview-modal"
            ref="doc-preview-modal"
            ok-only
            ok-title="Accept"
            centered
            size="xl"
            title="CV Preview"
        >
            
            <div>
                <div>
                    <iframe :src="canDocLink" frameborder="0" height="1200px" width="100%"></iframe>
                </div>
            </div>
        </b-modal>
      
    </div>
    <div v-if="pdf">
      <iframe :src="blink" frameborder="0" height="1200px" width="100%"></iframe>
    </div>
    <div v-if="docx || doc">
      <a class="font-weight-bolder" :href="mlink" target="_blank" v-if="docx || doc"> Preview CV</a>
      <iframe :src="mlink" frameborder="0" height="1200px" width="100%"></iframe>
    </div>
    <div v-if="rtf">
      <iframe :src="glink" frameborder="0" height="1200px" width="100%"></iframe>
    </div>
    <div v-if="otherDoc">
      <iframe :src="mlink" frameborder="0" height="1200px" width="100%"></iframe>
    </div>
    <div>
      <!-- <b-row>
        <b-col cols="6" xl="6" md="6">
          <iframe :src="blink" frameborder="0" height="800px" width="100%"></iframe>    
        </b-col>
        <b-col cols="6" xl="6" md="6">
          <iframe :src="glink" frameborder="0" height="800px" width="100%"></iframe>
        </b-col>
      </b-row> -->
    </div>
    
    
    
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BCard,
  BModal,
  BBadge,
  BForm,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormInput,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BFormFile,
  BCardText,
  BFormCheckbox
} from "bootstrap-vue";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from "moment";
import vSelect from 'vue-select'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import { avatarText } from "@core/utils/filter";
//import candidateStoreModule from "../candidateStoreModule";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import JobService from "@/services/job-service";
import CandidateService from "@/services/candidate-service";
import jobTimelineService from "@/services/job-timeline";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emailTemplates from "@/services/email-templates";
import mailService from "@/services/mail-service"
import UserService from "@/services/user-service"
import { quillEditor } from 'vue-quill-editor'
// import VueDocPreview from 'vue-doc-preview'



export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm._backRoute = from.path
      //console.log("from", from.path)
    })
  },
  data() {
    return {
      pausedFor7Seconds: false,
      cStatusOption: [
        
      ],
      // cStatusOptionSourcer: [
        
      // ],
      NotInterestedReason: [
        { title: "Not Looking for Change"},
        { title: "Not ok with the Client/Company"},
        { title: "Not ok with Location"},
        { title: "Not ok with Shift"},
        { title: "Not ok with Compensation"},
        { title: "Not ok with Designation/Role"},
        { title: "Recently Changed"},
        { title: "Already Interviewed/Duplicate"},
        { title: "Already working with Organization"},
        { title: "Re-Hire"},
        { title: "Ex-Employee"},
        { title: "Other"},
      ],
      NotAnsweredOptions: [
        { title: 'Not Answered'},
        { title: 'Not Reachable'},
        { title: 'Wrong Number'},
        { title: 'Call Back'}
      ],
      DuplicateOptions: [
        { title: 'Other Vendor Source Lock'},
        { title: 'Profile in Cooling Period'},
      ],
      coolingTimelineOptions: [
        { title: '0', value: 0},
        { title: '1 Month', value: 30},
        { title: '2 Months', value: 60},
        { title: '3 Months', value: 90},
        { title: '4 Months', value: 120},
        { title: '5 Months', value: 150},
        { title: '6 Months', value: 180},
      ],
      dupReasonSelected: null,
      coolingTimeline: null,
      NI_NR_comment : '',
      statusSelected: "",
      prevStatus: '',
      statusCSelected: "",
      error_status: "",
      jobId: '',
      userRole: '',
      emp_id: '',
      breadcrumb: [],
      recruiters: [],
      candidateData: [],
      glink: '',
      dlink: '',
      updateButton: true,
      jobBySourcer: '',
      intNotIntNotRelVisible: '',
      updateButtonVisible: '',
      position_name: '',
      locations: '',
      client: '',
      rec_name: '',
      attachments: '',
      sourcer_name: '',
      am_name: '',
      NI_Reason_Selected: '',
      NI_NR_comment: '',
      error_NI_NR_comment: '',
      NI: true,
      NA_comment: '',
      NA_Reason_Selected: '',
      error_NA_comment: '',
      blink: '',
      pdf: false,
      docx: false,
      rtf: false,
      doc: false,
      otherDoc: false,
      mlink: '',
      jobCode: '',
      selectionDate: null,
      offeredDate: null,
      joiningDate: null,
      attritionDate: null,
      error: '',
      isValid: true,
      avatarText,
      allUsers: [],
      sourcer: '',
      recruiter: '',
      loading: true,
      candidateJobs: [],
      jobData: {},
      offeredCTC: null,
      dropReason: '',
      notOfferedOptions: [
        {title: 'BGV Failed'},
        {title: 'Documentation Issue'},
        {title: 'CTC Issue'},
        {title: 'Notice Period Issue'},
        {title: 'Location Issue'},
        {title: 'Designation Issue'},
        {title: 'Position Closed'},
        {title: 'Position On-Hold'}
      ],
      offerDropOptions: [
        {title: 'Got Another Offer'},
        {title: 'Retained By Current Organization'},
        {title: 'CTC Issue'},
        {title: 'Notice Period Issue'},
        {title: 'Location Issue'},
        {title: 'Designation Issue'},
        {title: 'BGV/Documentation Issue'},
      ],
      savedPosition: 0,
      flatpickrConfig: {
        maxDate: "today"
      },
      jobPage: null,
      variableCTC: null,
      // band: null ,
      positionType: '',
      payroll: '',
      category: '',
      vertical: '',
      blueCollar: false,
      cvPresent: true,
      documents: {
        aadhaarCard: '',
        panCard: '',
        passportPhoto: '',
        bankProof: '',
        skillCertificate: ''
      },
      aadhaarUploaded: false,
      panUploaded: false,
      photoUploaded: false,
      certificateUploaded: false,
      bankUploaded: false,
      showAadhaarUpload: true,
      showPanUpload: true,
      showPhotoUpload: true,
      showCertificateUpload: true,
      showBankUpload: true,
      file: '',
      canDocLink: null,
      showCandidateDetails: true,
      // aadhaarLink: null,
      // panLink: null,
      // photoLink: null,
      // skillCertificateLink: null,
      // bankProofLink: null
      skillCertificateAvailable: true,
      contractStartDate: null,
      contractEndDate: null,
      rateCard: null,
      monthlyCTC: null,
      probationDays: null,
      today: null,
      daysDifference: null,
      offerLetterContent: null,
      offerDetails: null,
      // blueCollar: false
      snowOption: {
        theme: 'snow',
      },
    };
  },
  directives: {
     'b-tooltip': VBTooltip, Ripple
  },
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCardActions,
    BCard,
    BModal,
    BBadge,
    BForm,
    BFormGroup,
    AppTimeline,
    AppTimelineItem,
    BButton,
    vSelect,
    BFormTextarea,
    ToastificationContent,
    // VueDocPreview,
    flatPickr,
    BAvatar,
    BFormInput,
    BFormFile,
    BCardText,
    BFormCheckbox,
    quillEditor
    // VBTooltip

  },
  /*computed: {
    candidateId() {
      return `SHC-${( parseInt(this.cid) + 1000)}`
    }
  },*/
  created() {
    console.log('Environment:', process.env.NODE_ENV)
    // UserService.getAllUsersDdActiveInactive().then(res => {
    //   //console.log(res)
    //   this.allUsers = res.data
    // })
    // const scrollPosition = localStorage.getItem('lastPosition')
    //   //console.log('called',JSON.stringify(scrollPosition))
    //   //console.log('last position', JSON.parse(scrollPosition).y  )
    // this.savedPosition = JSON.parse(JSON.stringify(localStorage.getItem('lastPosition'))).y 

    this.id = this.$route.params.id
    this.jobId = this.$route.params.jobId
    if (this.$route.query) {
      this.position_name = this.$route.query.position_name
      this.locations = this.$route.query.locations
      this.client = this.$route.query.client
      this.recruiter = this.$route.query.recruiter
      this.attachments = this.$route.query.attachments
      this.sourcer = this.$route.query.sourcer
      this.am_name = this.$route.query.am_name
      this.jobCode = this.$route.query.job_id
      this.jobPage = this.$route.query.page
      this.positionType = this.$route.query.position_type
      this.category = this.$route.query.category
      this.payroll = this.$route.query.payroll
      this.vertical = this.$route.query.vertical
    }
    console.log('route query: ', this.positionType, this.category, this.vertical, this.payroll)
    if(this.positionType === 'Contractual' && this.category === 'Blue Collar' && this.payroll === 'Sattva Payroll' && this.vertical === 'Infra Industry') {
      this.blueCollar = true
      console.log('job type blue collar: ', this.blueCollar)
    }
    //console.log(this.jobId, this.id, this.$route.query)
    this.getCandidateData()
    this.today = new Date().getDate()

    //console.log('hiring details', this.recruiter, this.sourcer, this.am_name)
    
    // UserService.getAllUsersDdActiveInactive().then(res => {
    //   this.recruiters = res.data
    //   console.log('recruiters',this.recruiters)
    // })
    UserService.getAllRecruitersList().then(res => {
      this.recruiters = res.data
      console.log('recruiters list',this.recruiters)
      const userData = JSON.parse(localStorage.getItem("userData"));
      const userMgr = this.recruiters.filter(item => item.emp_id === userData.mgr_id)
      const p_mgr_id = userMgr[0].manager
      console.log('Manager Detail:', userMgr, p_mgr_id)
    })
    this.jobId = this.jobId !== 'null' ? this.jobId : null
    if(this.jobId) {
      CandidateService.getCandidateCurrentStatus(this.id, this.jobId).then( res => {
      //console.log('candidate Status', res)
      if (res.status === 'OK') {

        this.prevStatus = res.data[0].candidateStatus
        
        this.computeStatusOption()
      }
    })
    }
    

    
    this.userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    console.log('Current user Role: ', this.userRole)
    this.emp_id = JSON.parse(localStorage.getItem('userData')).emp_id
    console.log('job type blue collar: ', this.blueCollar)
    
  },
    
  watch: {
    prevStatus: function(newStatus) {
      this.computeStatusOption()
      if(this.positionType === 'Contractual' && this.category === 'Blue Collar' && this.payroll === 'Sattva Payroll' && this.vertical === 'Infra Industry') {
        this.blueCollar = true
        console.log('job type blue collar: ', this.blueCollar)
      }
    },
    'aadhaarUploaded': 'computeDocumentDone',
    'panUploaded': 'computeDocumentDone',
    'photoUploaded': 'computeDocumentDone',
    'certificateUploaded': 'computeDocumentDone',
    'bankUploaded': 'computeDocumentDone'
  },
  methods: {
    computeStatusOption() {
      this.jobId = this.jobId !== 'null' ? this.jobId : null
      if(this.jobId) {
        //const jobData = this.candidateJobs.filter(item => item.id === this.jobId )[0]
        // if(this.blueCollar) {
        //   if (this.prevStatus === 'ProfileShared') {
        //     this.cStatusOption = [
        //       { value: "Confirmed", text: "Confirmed" },
        //       { value: "Drop", text: "Drop" }
        //     ]
        //   } else if (this.prevStatus === 'Confirmed') {
        //     this.cStatusOption = [
              
        //       { value: "Drop", text: "Drop" }
        //     ]
        //   } else if (this.prevStatus === 'Document Submitted') {
        //     this.cStatusOption = [
        //       { value: "Joined", text: "Joined" },
        //       { value: "Drop", text: "Drop" },
        //     ]
        //   } 
          
        //   else if (this.prevStatus === 'Joined' || this.prevStatus === 'LOI Issued') {
        //     this.cStatusOption = [
        //       { value: "Early Attrition", text: "Early Attrition" },
        //       // { value: "Attrition", text: "Attrition" },
        //     ]
        //     console.log('inside loi status check', this.daysDifference, this.candidateJobs[0].loi_issued)
        //     if(this.daysDifference >= 7 && this.daysDifference < 10 && (!this.candidateJobs[0].loi_issued || this.candidateJobs[0].loi_issued === false)) {
        //       this.cStatusOption = [
        //         { value: "Early Attrition", text: "Early Attrition" },
        //         { value: "LOI Issued", text: "LOI Issued" },
        //       ]
        //     }
        //     if(this.daysDifference >= 90) {
        //       this.cStatusOption = [
        //         { value: "Early Attrition", text: "Early Attrition" },
        //         { value: "Attrition", text: "Attrition" },
        //         // { value: "LOI Issued", text: "LOI Issued" },
        //       ]
        //     }
        //     // if(this.joiningDate )
        //   }
        // }
        if (this.blueCollar) {
          if (this.prevStatus === 'ProfileShared' || this.prevStatus === 'Confirmed') {
            this.cStatusOption = [
              { value: "Interview", text: "Interview" },
              // { value: "Confirmed", text: "Confirmed" },
              { value: "Rejected", text: "Rejected" }
            ];
          } else if (this.prevStatus === 'Interview') {
            this.cStatusOption = [
              { value: "Shortlisted", text: "Shortlisted" },
              { value: "Rejected", text: "Rejected" }
            ];
          } else if (this.prevStatus === 'Shortlisted') {
            this.cStatusOption = [
              { value: "Offered", text: "Offered" },
              { value: "Drop", text: "Drop" }
            ];
          } else if (this.prevStatus === 'Offered') {
            this.cStatusOption = [
              { value: "Joined", text: "Joined" },
              { value: "Drop", text: "Drop" }
            ];
          } else if (this.prevStatus === 'Joined') {
            this.cStatusOption = [
              { value: "Attrition", text: "Attrition" },
            ]; 
          }
        }
         else {
          if (this.prevStatus === 'Initiated') {
            this.cStatusOption = [
              { value: "Review Reject", text: "ReviewReject" },
              { value: "Profile Shared", text: "ProfileShared" }
            ]
          } else if (this.prevStatus === 'ProfileShared') {
            this.cStatusOption = [
              { value: "Screen Reject", text: "ScreenReject" },
              { value: "Screen Select", text: "ScreenSelect" },
              { value: "Duplicate", text: "Duplicate" },
              { value: "Inprocess", text: "Inprocess" },
              { value: "OnHold", text: "OnHold" },
            ]
          } else if (this.prevStatus === 'ScreenSelect') {
            this.cStatusOption = [
              { value: "OnHold", text: "OnHold" },
              { value: "Inprocess", text: "Inprocess" },
              { value: "Rejected", text: "Rejected" }
            ]
          } else if (this.prevStatus === 'Inprocess') {
            this.cStatusOption = [
              { value: "OnHold", text: "OnHold" },
              { value: "Shortlisted", text: "Shortlisted" },
              { value: "Rejected", text: "Rejected" },
              { value: "Duplicate", text: "Duplicate" },
            ]
          } else if (this.prevStatus === 'OnHold') {
            this.cStatusOption = [
              { value: "Inprocess", text: "Inprocess" },
              { value: "Shortlisted", text: "Shortlisted" },
              { value: "Rejected", text: "Rejected" },
              { value: "Duplicate", text: "Duplicate" },
            ]
          } else if (this.prevStatus === 'Shortlisted') {
            this.cStatusOption = [
              { value: "Offered", text: "Offered" },
              { value: "Not Offered", text: "NotOffered" },
              { value: "Duplicate", text: "Duplicate" },
            ]
          } else if (this.prevStatus === 'NotOffered') {
            this.cStatusOption = [
              { value: "Offered", text: "Offered" },
            ]
          } else if (this.prevStatus === 'Offered') {
            this.cStatusOption = [
              { value: "Joined", text: "Joined" },
              { value: "Offer Drop", text: "OfferDrop" },
              { value: "Duplicate", text: "Duplicate" },
            ]
          } else if (this.prevStatus === 'OfferDrop') {
            this.cStatusOption = [
              { value: "Joined", text: "Joined" },        
            ]
          } else if (this.prevStatus === 'Joined') {
            this.cStatusOption = [
              { value: "Offer Drop", text: "OfferDrop" },
              { value: "Duplicate", text: "Duplicate" },
              { value: "Early Attrition", text: "Early Attrition" },
              { value: "Attrition", text: "Attrition" },
            ]
          }
        }
        
      }
      //console.log('inside compute')
      
    },
    differenceInDays(date1, date2) {
      console.log('in loi check dates', date1, date2)
      const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
      const diffTime = Math.abs(date2 - date1); // difference in milliseconds
      return Math.ceil(diffTime / oneDay); // convert to days and round up
    },
    getCandidateData() {
      CandidateService.getCandidateViewData(this.id).then(res => {
        console.log(res)
        if(res.status === "OK") {
          this.candidateData = res.data.candidateData[0]
          this.candidateJobs = res.data.candidateJobs
          
          if(this.candidateData.documents) {
            if(this.candidateData.documents.aadhaar) {
              this.aadhaarUploaded = true
              this.showAadhaarUpload = false
            }
            if(this.candidateData.documents.pan) {
              this.panUploaded = true
              this.showPanUpload = false
            }
            if(this.candidateData.documents.photo) {
              this.photoUploaded = true
              this.showPhotoUpload = false
            }
            if(this.candidateData.documents.skillCertificate) {
              this.certificateUploaded = true
              this.showCertificateUpload = false
            }
            if(this.candidateData.documents.bankProof) {
              this.bankUploaded = true
              this.showBankUpload = false
            }
          }
          
          let key = null
          if(this.candidateData.cv_path) {
            key = this.candidateData.cv_path.replace("/","%2F")

            const temp = key.split(".");
            const cStatus = this.candidateJobs.filter(item => item.id === this.jobId )[0]
            this.prevStatus = cStatus ? cStatus.candidateStatus : ''
            const extension = temp[temp.length - 1]; // Or parts.pop();
            ////console.log(extension)
            if(extension === 'pdf') {
              this.pdf = true
              this.docx = false
              this.doc = false
              this.otherDoc = false
              this.rtf = false
            } else if (extension === 'docx'){
              this.pdf = false
              this.docx = true
              this.doc = false
              this.otherDoc = false
              this.rtf = false
            } else if (extension === 'doc'){
              this.pdf = false
              this.docx = false
              this.doc = true
              this.otherDoc = false
              this.rtf = false
            }else if (extension === 'rtf'){
              this.pdf = false
              this.docx = false
              this.doc = false
              this.rtf = true
              this.otherDoc = false
            } else if (extension !== 'docx' || extension !== 'doc' || extension !== 'pdf' || extension !== 'rtf') {
              this.pdf = false
              this.docx = false
              this.doc = false
              this.rtf = false
              this.otherDoc = true
            } 
            
            JobService.getFile(key)
              .then(res => {
                //console.log(res)
                this.dlink = res.file;
                var encodedUrl = encodeURIComponent(res.file);
                this.glink = //'http://192.168.1.34:5000/download/' + response.data.cv_path
                  "https://docs.google.com/viewer?url=" + encodedUrl + "&embedded=true";

                this.mlink = "https://view.officeapps.live.com/op/view.aspx?src=" + encodedUrl
                ////console.log(this.dlink)
                this.dlink = res.file;
            });
            if(this.pdf) {
              JobService.getFileBase64(key)
                .then(res => {
                  ////console.log(res)
                  const base64 = res.file;
                  //var encodedUrl = encodeURIComponent(res.file);
                  this.blink = "data:application/pdf;base64," + base64
                  
              }); 
            }
          } else {
            this.cvPresent = false
          }
          
          //console.log('testcase', this.candidateJobs.createdAt, moment(this.candidateJobs.createdAt).format("DD MMM YYYY"))
          
          
          if (this.jobId) {
            //console.log('jobid', this.jobId, typeof this.jobId, this.candidateJobs, this.candidateJobs.filter(item => item.id == this.jobId))
            const temp1 = this.candidateJobs.filter(item => item.id == this.jobId)
            const temp = temp1 && temp1.length > 0 ? temp1[0].sourcer : null
            //console.log('assigned to job',temp)
            this.jobBySourcer = temp ? 'sourcer' : 'non-sourcer'
            if (temp && temp.length > 0) {
              CandidateService.getCandidateCurrentStatus(this.id, this.jobId).then( res => {
                if (res.status === 'OK') {
                  // console.log('candidate status', res)
                  this.prevStatus = res.data[0].candidateStatus
                  if(this.prevStatus === 'Joined' && this.category === 'Blue Collar') {
                    const today = new Date()
                    const joining_date = new Date(this.candidateJobs[0].joining_date)                    
                    this.daysDifference = this.differenceInDays(today, joining_date)
                    console.log('days difference: ', this.daysDifference)
                  }
                }
              })
            }
            this.updateButtonVisible = this.CandidateBelongsToJob()
            this.intNotIntNotRelVisible = this.CandidateBelongsToSourcerJob()
            //console.log(this.updateButtonVisible, this.intNotIntNotRelVisible)
          
          }

        } else if ((res.status === 401 ) || (res.status === 403 )) {
          window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
        } else if ((res.status === 400 ) ) {
          this.error = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
        } else if ((res.status === 500 ) ) {
          this.error = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
        } else {
          this.error = res
        }
        
        
      })

    },
    showCModal(id, status) {
      this.candidate_id = id;
      //console.log('compute status')
      this.computeStatusOption()
      //console.log('compute status end')
      this.$refs["modal-cupdate-status"].show();
    },
    showNINRModal(val) {
      if (val === 'nr') {
        this.NI = false
      } else {
        this.NI = true
      }
      this.$refs["modal-ni-nr-reason"].show();
    },
    showNotAnsweredModal() {
      this.$refs["modal-na-status"].show();
    },
    showDupModal() {
      this.$refs["modal-dup-status"].show();
    },
    selectCStatus(status) {
      this.statusCSelected = status;

      //this.clicked = true
      ////console.log(this.statusCSelected);
      
      for (var i = 0; i < this.cStatusOption.length; i++) {
        if (this.cStatusOption[i].text === status) {
          document.getElementById(this.cStatusOption[i].text).classList.remove('badge-light')
          document.getElementById(this.cStatusOption[i].text).classList.add('badge-primary')
          var el = document.getElementById(status);
          el.style.color = 'white';
        } else {
          document.getElementById(this.cStatusOption[i].text).classList.remove('badge-primary')
          document.getElementById(this.cStatusOption[i].text).classList.add('badge-light')
          var el = document.getElementById(this.cStatusOption[i].text);
          el.style.color = 'black';
        }
      }
    },
    isLoggedIn() {
      const expireIn = localStorage.getItem('expireIn')
      if (Math.floor(Date.now() / 1000 > expireIn)) {
          window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
          ////console.log("called logout")
          localStorage.clear();
          this.$router.push({ name: "auth-login" });
      }
    },
    UpdateNotInterested() {
      this.isLoggedIn()
      this.statusCSelected = 'Not Interested'
      this.candidate_id = this.id
      this.updateCandidateStatus()

    },
    UpdateNotRelevant() {
      this.isLoggedIn()
      this.statusCSelected = 'Not Relevant'
      this.candidate_id = this.id
      this.updateCandidateStatus()
    },
    updateNotAnswered() {
      this.isLoggedIn()
      //console.log('called not answered', this.NA_Reason_Selected.title)
      this.statusCSelected = this.NA_Reason_Selected.title
      this.candidate_id = this.id
      this.updateCandidateStatus()
    },
    updateDuplicate() {
      this.isLoggedIn()
      //console.log('called not answered', this.NA_Reason_Selected.title)
      this.statusCSelected = 'Duplicate'
      this.candidate_id = this.id
      this.updateCandidateStatus()
    },
    updateCandidateStatus() {
      this.isLoggedIn()
       if (!this.statusCSelected) {
        this.error_status = 'Please Select Status!!'
        //this.$refs["modal-cupdate-status"].show();
        this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Failed!!! Status Not Selected",
                  icon: "EditIcon",
                  variant: "danger",
                },
        });
        return
       }
      ////console.log(this.statusCSelected, this.candidate_id);
      const userData = JSON.parse(localStorage.getItem("userData"));
      //const allUsers = JSON.parse(localStorage.getItem("allusers"));
      const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
      const username = `${userData.first_name} ${userData.last_name}`
      const useremail = userData.email
      const userEmpId = userData.emp_id
      const userMgr = this.recruiters.filter(item => item.emp_id === userData.mgr_id)
      console.log('Manager Detail:', userMgr)
      const p_mgr_id = userMgr[0].manager
      let status = {}
      let contractDetails = {}
      //console.log('Status Selected', this.statusCSelected)
      // if(this.statusCSelected === 'Joined') {
      //   //console.log('Joining Date', this.joiningDate)
      // }
      if( this.statusCSelected !== 'Not Interested' && this.statusCSelected !== 'Not Relevant' && this.statusCSelected !== 'Duplicate' && this.statusCSelected !== 'Not Answered' && this.statusCSelected !== 'Not Reachable' && this.statusCSelected !== 'Wrong Number' && this.statusCSelected !== 'Call Back' && this.statusCSelected !== 'Shortlisted'  && this.statusCSelected !== 'Offered' && this.statusCSelected !== 'Joined' && this.statusCSelected !== 'Early Attrition' && this.statusCSelected !== 'OfferDrop' && this.statusCSelected !== 'NotOffered'  && this.statusCSelected !== 'ProfileShared' && this.statusCSelected !== 'LOI Issued' && this.statusCSelected !== 'Interview') {
        status = {
          candidateStatus: this.statusCSelected,
          updatedBy: user,
          //recruiter: userData.emp_id
        };
      } else if(this.statusCSelected === 'Not Interested'){
        
          status = {
            candidateStatus: this.statusCSelected,
            updatedBy: user,
            reason: this.NI_Reason_Selected.title,
            comment: this.NI_NR_comment,
            recruiter: userData.emp_id,
            mgr_id: userData.mgr_id,
            p_mgr_id: p_mgr_id,
            // "Manager Name": this.userRole === 'TALENT-ADVISOR-SOURCER' ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
            "Manager Name": this.$can('read','talent_acquisition') ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
            "Employee Name": username.toUpperCase(),
          };
      } else if(this.statusCSelected === 'Not Relevant'){
        status = {
          candidateStatus: this.statusCSelected,
          updatedBy: user,
          comment: this.NI_NR_comment,
          recruiter: userData.emp_id,
          mgr_id: userData.mgr_id,
          p_mgr_id: p_mgr_id,
          // "Manager Name": this.userRole === 'TALENT-ADVISOR-SOURCER' ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
          "Manager Name": this.$can('read','talent_acquisition') ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
          "Employee Name": username.toUpperCase(),
        };
      }
      else if(this.statusCSelected === 'Duplicate'){
        console.log(this.dupReasonSelected)
        status = {
          candidateStatus: this.statusCSelected,
          updatedBy: user,
          reason: this.dupReasonSelected.title + '| Cooling Time: ' + this.coolingTimeline.value + ' days',
          recruiter: userData.emp_id,
          mgr_id: userData.mgr_id,
          p_mgr_id: p_mgr_id,
          // "Manager Name": this.userRole === 'TALENT-ADVISOR-SOURCER' ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
          "Manager Name": this.$can('read','talent_acquisition') ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
          "Employee Name": username.toUpperCase(),
        };
      }
      else if(this.statusCSelected === 'Not Answered' || this.statusCSelected === 'Not Reachable' || this.statusCSelected === 'Wrong Number' || this.statusCSelected === 'Call Back'){
        //console.log('inside NA', this.statusCSelected, this.NA_comment, user)
        status = {
          candidateStatus: this.statusCSelected,
          updatedBy: user,
          comment: this.NA_comment,
          recruiter: userData.emp_id,
          mgr_id: userData.mgr_id,
          p_mgr_id: p_mgr_id,
          // "Manager Name": this.userRole === 'TALENT-ADVISOR-SOURCER' ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
          "Manager Name": this.$can('read','talent_acquisition') ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
          "Employee Name": username.toUpperCase(),
        };
      } else if(this.statusCSelected === 'Interview' ){
        //console.log('inside NA', this.statusCSelected, this.NA_comment, user)

          if(!this.candidateData.documents.aadhaar  || !this.candidateData.documents.pan || !this.candidateData.documents.photo || (!this.candidateData.documents.skillCertificate && this.skillCertificateAvailable) ) {
  //|| !this.candidateData.documents.bankProof
            this.error = 'Aadhaar, PAN, Skill Certificate & Photo is mandatory for Interview Status!!'
            this.isValid = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed!!! " + this.error,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            return
            
          }
          this.isValid = true
          this.error = ''
          status = {
            candidateStatus: this.statusCSelected,
            updatedBy: user,
            // comment: this.NA_comment,
            recruiter: userData.emp_id,
            mgr_id: userData.mgr_id,
            p_mgr_id: p_mgr_id,
            // "Manager Name": this.userRole === 'TALENT-ADVISOR-SOURCER' ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
            "Manager Name": this.$can('read','talent_acquisition') ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
            "Employee Name": username.toUpperCase(),
          };
          
        } else if(this.statusCSelected === 'Shortlisted'){
          //console.log('check if update allowed', this.statusCSelected)
          if (!this.selectionDate) {
            this.error = 'Selection Date is mandatory!!';
            this.isValid = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed!!! " + this.error,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            return;
          }

          if (this.blueCollar && !this.candidateData.documents.bankProof) {
            this.error = 'Bank Proof Document is mandatory for Shortlisted Blue Collar Candidates!!';
            this.isValid = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed!!! " + this.error,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            return;
          }

          this.isValid = true;
          this.error = '';
          status = {
            candidateStatus: this.statusCSelected,
            updatedBy: user,
            selection_date: this.selectionDate,
            //recruiter: userData.emp_id
          };
      } else if(this.statusCSelected === 'Offered'){
        //console.log('check if update allowed', this.statusCSelected)
        if((!this.offeredDate || !this.offeredCTC || this.offeredCTC < 9999 || !this.variableCTC ) && this.client !== 'Sattva Human Pvt Ltd' && !this.blueCollar) {
          
            
          this.error = 'Offered Date, Offered CTC, Variable CTC is mandatory & Mention complete CTC in rupees!!'
          this.isValid = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed!!! " + this.error,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          return
          //return this.error
        } else if((!this.offeredDate) && this.client === 'Sattva Human Pvt Ltd' && !this.blueCollar) {
          
            
          this.error = 'Offered Date is mandatory!!'
          this.isValid = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed!!! " + this.error,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          return
          //return this.error
        } else if (this.blueCollar) {
          if((!this.offeredDate || !this.offeredCTC || this.offeredCTC < 9999  ) && this.client !== 'Sattva Human Pvt Ltd' && !this.blueCollar) {
          
            
          this.error = 'Offered Date, Offered CTC is mandatory & Mention complete CTC in rupees!!'
          this.isValid = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed!!! " + this.error,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          return
          //return this.error
        }
        }
        this.isValid = true
          this.error = ''
          status = {
            candidateStatus: this.statusCSelected,
            updatedBy: user,
            offered_date: this.offeredDate,
            joining_date: this.joiningDate,
            offered_ctc: this.offeredCTC,
            variable_ctc: this.variableCTC,
            // band: this.band
            //recruiter: userData.emp_id
          };  
      } else if(this.statusCSelected === 'Joined'){
        ////console.log(this.joiningDate)
        //console.log('check if update allowed', this.statusCSelected, this.joiningDate)
        if((this.positionType === 'Contractual' || this.positionType === 'Cost Plus') && this.category === 'White Collar' && this.payroll === 'Sattva Payroll') {
          if(!this.joiningDate && !this.contractStartDate && !this.contractEndDate && !this.rateCard && !this.monthlyCTC && !this.probationDays) {
            this.error = 'All Fields are mandatory !!'
            this.isValid = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed!!! " + this.error,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            return
          }
        } else {
          if(!this.joiningDate) {
            this.error = 'Joining Date is mandatory !!'
            this.isValid = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed!!! " + this.error,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            return
          }
        }
        
        this.isValid = true
          this.error = ''
          status = {
            candidateStatus: this.statusCSelected,
            updatedBy: user,
            joining_date: this.joiningDate
            //recruiter: userData.emp_id
          };
          // if(this.positionType === 'Contractual' && this.vertical === 'Service Industry' && this.category === 'White Collar' && this.payroll === 'Sattva Payroll') {
          if((this.positionType === 'Contractual' || this.positionType === 'Cost Plus') && this.category === 'White Collar' && this.payroll === 'Sattva Payroll') {
            contractDetails = {
              contractStartDate: this.contractStartDate,
              contractEndDate: this.contractEndDate,
              rateCard: this.rateCard,
              monthlyCTC: this.monthlyCTC,
              probationDays: this.probationDays,
              status: 'ACTIVE'
            }
            status.contractDetails = contractDetails
          }
          
      } else if(this.statusCSelected === 'OfferDrop' || this.statusCSelected === 'NotOffered'){
        //console.log('check if update allowed', this.statusCSelected)
        if(!this.dropReason || this.dropReason.length < 1) {
          this.error = 'Drop Reason is mandatory !!'
          this.isValid = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed!!! " + this.error,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          return
        }
        this.isValid = true
        this.error = ''
          status = {
            candidateStatus: this.statusCSelected,
            updatedBy: user,
            drop_reason: this.dropReason
          };
      } else if(this.statusCSelected === 'ProfileShared' ){
        //console.log('check if update allowed', this.statusCSelected)
        
          status = {
            candidateStatus: this.statusCSelected,
            updatedBy: user,
            recruiter: userData.emp_id,
            mgr_id: userData.mgr_id,
            p_mgr_id: p_mgr_id,
            // "Manager Name": this.userRole === 'TALENT-ADVISOR-SOURCER' ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
            "Manager Name": this.$can('read','talent_acquisition') ? userMgr[0].title.toUpperCase() : username.toUpperCase(),
            "Employee Name": username.toUpperCase(),

          };
      } else if(this.statusCSelected === 'Early Attrition' || this.statusCSelected === 'Attrition'){
        ////console.log(this.joiningDate)
        //console.log('check if update allowed', this.statusCSelected, this.joiningDate)
        if(!this.attritionDate) {
          this.error = 'Attrition Date is mandatory !!'
          this.isValid = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed!!! " + this.error,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          return
        } 
        this.isValid = true
          this.error = ''
          status = {
            candidateStatus: this.statusCSelected,
            updatedBy: user,
            attrition_date: this.attritionDate
            //recruiter: userData.emp_id
          };
      } else if(this.statusCSelected === 'LOI Issued' && this.prevStatus === 'Joined'){
        //console.log('check if update allowed', this.statusCSelected)
        // if(!this.selectionDate) {
        //   this.error = 'Selection Date is mandatory !!'
        //   //return this.error
        //   this.isValid = false
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: "Failed!!! " + this.error,
        //       icon: "EditIcon",
        //       variant: "danger",
        //     },
        //   });
        //   return
        // }  
        // this.isValid = true
        //   this.error = ''
          status = {
            candidateStatus: this.prevStatus,
            updatedBy: user,
            // loi_issued: true,
            // comment: 'NO LOI SENT'
              //recruiter: userData.emp_id
          };
      }
      //else if(this.statusCSelected === 'Document Submitted' ){
      //   //console.log('check if update allowed', this.statusCSelected)
        
      //     status = {
      //       candidateStatus: this.statusCSelected,
      //       updatedBy: user,
      //     };
      // }

      
      //console.log('status',status) 
      //console.log('check if update allowed',this.selectionDate, this.offeredDate, this.joiningDate, this.error, this.isvalid)
      if(this.isValid === true) {

        const timelineData = {
                title: `Candidate - ${this.id} Status Changes to ${this.statusCSelected}`,
                description: `CS-Changed`, /// CS-Changed means candidate status changed
                type: "success",
                createdBy: username,
                jobId: this.jobId,
                candidate_id: this.id,
                candidateStatus: this.statusCSelected,
              }

        status.jt = timelineData  
        // if(status.candidateStatus === 'Joined') {

        // }
        
        CandidateService.updateCandidateStatus(this.id, this.jobId, status).then(
          (res) => {
            
            if (res.status === "OK") {
              console.log('candidate updated:', res.data);
              const updateCandidateResponse = res.data
              
              if(!this.recruiter) {
                this.$router.push({
                //name: this.$route.name, // Assuming you are using named routes
                query: {
                  //...this.$route.query, // Spread operator to copy all existing query params
                  recruiter: userEmpId, // Update the recruiter query parameter
                  position_name: this.$route.query.position_name,
                  client: this.$route.query.client,
                  attachments: this.$route.query.attachments,
                  locations: this.$route.query.locations,
                  sourcer: this.$route.query.sourcer,
                  am_name: this.$route.query.am_name,
                  job_id: this.$route.query.job_id,
                  jobPage: this.$route.query.page,
                  positionType: this.$route.query.position_type,
                  category: this.$route.query.category,
                  payroll: this.$route.query.payroll,
                  vertical: this.$route.query.vertical
                }
              });
              }
              // if(this.userRole === 'TALENT-ADVISOR-SOURCER' ) {
              // if(this.$can('read','talent_acquisition') ) {
                console.log('recruiter data b4', this.recruiter)
                this.recruiter = !this.recruiter ? userEmpId : this.recruiter;
              // } else {
                
              // }
              console.log('recruiter data after', this.recruiter)

              
              //console.log(timelineData);
              const rec = this.recruiters.filter(item => item.emp_id === this.recruiter)
              console.log('filtered',rec, this.recruiter)
              const rec_email = rec[0].email
              const rec_name = rec[0].title
              //const rec_empid = recruiter[0].emp_id
              const rec_mgr = rec[0].manager
              const tl = this.recruiters.filter(item => item.emp_id === rec_mgr)
              console.log('recruiter',rec, rec_email, tl)
              const tl_name = tl[0].title
              let tl_email = tl[0].email
              const infra_email = this.vertical === 'Infra Industry' ? ',sumeet.upadhye@sattvahuman.com' : ''
              tl_email = tl_email + infra_email
              console.log('recruiter mail data:',rec, rec_email, tl, tl_email)
              const acc_mgr = this.recruiters.filter(item => item.title === this.am_name)
              //console.log('acc manager', acc_mgr)
              const am_email = acc_mgr.length > 0 ? acc_mgr[0].email : ''
              const sourcer = this.recruiters.filter(item => item.title === this.sourcer)
              //console.log('sourcer', sourcer)
              const sourcer_email = sourcer.length > 0 ? sourcer[0].email : ''
              const sourcer_name = sourcer.length > 0 ? sourcer[0].title : ''
              const can_tmp = this.candidateJobs.filter(item => item.id === this.jobId)
              const can = can_tmp.length > 0 ? can_tmp[0] : []
              const added_on = can ? moment(can.createdAt).format("DD MMM YYYY") : ''
              //console.log('can', can, can_tmp)
              //const source = can ? can.source : ''

//              jobTimelineService.create(timelineData).then(res => {
//                console.log("timeline", res)
                  if(this.statusCSelected === "ProfileShared" && this.jobId) {
                    
                      //const recruiter = this.recruiters.filter(item => item.title === this.rec_name)
                      //const rec_email = recruiter[0].email
                      
                      if(rec_email) {
                        //console.log('client', this.client, this.locations, this.attachments, this.position_name, this.rec_name)
                        if( this.client !== 'LTI RPO' && this.client !== 'Dummy' && this.client !== 'Sonata Technologies' && this.client !== 'AXA XL-RR' && this.client !== 'Wipro RPO' && this.client !== 'Vector3 Digital Tech' && this.client !== 'Larsen & Toubro' && this.client !== 'Zamil Steel') {
                          const mailData = {
                            can_email: this.candidateData.email,
                            can_fname: `${this.candidateData.first_name} ${this.candidateData.last_name}`,
                            can_contact: this.candidateData.contact_no,
                            can_position: this.candidateData.curr_position,
                            can_company: this.candidateData.curr_company,
                            can_qualification: this.candidateData.qualification,
                            can_gender: this.candidateData.gender,
                            can_dob: this.candidateData.dob == '1900-01-01' ? '' : this.candidateData.dob,
                            can_exp: this.candidateData.exp,
                            can_ctc: this.candidateData.ctc,
                            can_exp_ctc: this.candidateData.exp_ctc,
                            can_skills: this.candidateData.skills,
                            can_city: this.candidateData.curr_location,
                            can_pref_city: this.candidateData.pref_location,
                            can_notice_period: this.candidateData.notice_period,
                            cv_file: this.candidateData.cv_path, 
                            am_email: `${useremail}` === 'sattva.delhi@sattvahuman.com' ? '' : useremail,
                            position_name: this.position_name,
                            client: this.client,
                            location: this.locations,
                            rec_email: rec_email,
                            rec_name: rec_name,
                            jd_file: this.attachments ? this.attachments : null//.replace("/","%2F")                     
                          }
                          //console.log("in if mail-1", mailData)
                          let mail = ''
                          if (this.attachments) {
                            mail = emailTemplates.candidateProfileShared(mailData)
                          } else {
                            mail = emailTemplates.candidateProfileSharedNoJD(mailData)
                          }
                          const mail_body = {
                            ...mail, // Use Sequelize's .dataValues to get a plain object of the model
                            timeline_id: updateCandidateResponse.timeline // Append the newly created timeline object
                          };
                          //console.log("in if mail-2", mail)
                          if (mail && process.env.NODE_ENV === "production") {
                            mailService.mail(mail_body).then(res => {
                              //console.log("in if mail-2", res)
                            })
                          }
                        }
                      }
                    //})
                  
                  } else if (this.statusCSelected === "Shortlisted" && this.jobId) {
                    
                      //console.log('recruiter',rec, rec_email, am_email, rec_name)
                      
                      // const selectionDate = can ? moment(can.selection_date).format("DD MMM YYYY") : ''
                      // const offeredDate = can ? moment(can.offered_date).format("DD MMM YYYY") : ''
                      // const joiningDate = can ? moment(can.joiining_date).format("DD MMM YYYY") : ''
                      if(rec_email) {
                        //console.log('client', this.client, this.locations, this.attachments, this.position_name, this.rec_name)
                        //if( this.client !== 'LTI RPO' && this.client !== 'Dummy' && this.client !== 'Sonata Technologies'  && this.client !== 'Wipro RPO') {
                          const mailData = {
                            can_email: this.candidateData.email,
                            can_fname: `${this.candidateData.first_name} ${this.candidateData.last_name}`,
                            can_contact: this.candidateData.contact_no,
                            can_id: this.candidateData.candidate_id,
                            position_name: this.position_name,
                            job_id: this.jobCode,
                            client: this.client,
                            location: this.locations,
                            rec_email: rec_email,
                            rec_name: rec_name,
                            tl_name: tl_name,
                            tl_email: tl_email,
                            sourcer_name: sourcer_name,
                            sourcer_email: sourcer_email,
                            am_email: am_email,
                            am_name: this.am_name,
                            added_on:  moment(added_on).format("DD MMM YYYY"),
                            selection_date: this.selectionDate ? moment(this.selectionDate).format("DD MMM YYYY") : 'Not Available',
                            source: can.source
                          }
                          //console.log("in if mail-1", mailData)
                          let mail = emailTemplates.selectionInternalMail(mailData)
                          const mail_body = {
                            ...mail, // Use Sequelize's .dataValues to get a plain object of the model
                            timeline_id: updateCandidateResponse.timeline // Append the newly created timeline object
                          };
                          //console.log("in if mail-2", mail)
                          //if (mail && process.env.NODE_ENV === "production") {
                            if (mail && process.env.NODE_ENV === "production") {  
                            mailService.mail(mail_body).then(res => {
                              //console.log("in if mail-2", res)
                            })
                          }
                        //}
                      }
                  } else if (this.statusCSelected === "Offered" && this.jobId) {
                    
                    //console.log('recruiter',rec, rec_email, am_email, rec_name)
                    if(rec_email) {
                      //console.log('client', this.client, this.locations, this.attachments, this.position_name, this.rec_name)
                      //if( this.client !== 'LTI RPO' && this.client !== 'Dummy' && this.client !== 'Sonata Technologies'  && this.client !== 'Wipro RPO') {
                        const mailData = {
                          can_email: this.candidateData.email,
                          can_fname: `${this.candidateData.first_name} ${this.candidateData.last_name}`,
                          can_contact: this.candidateData.contact_no,
                          can_id: this.candidateData.candidate_id,
                          position_name: this.position_name,
                          job_id: this.jobCode,
                          client: this.client,
                          location: this.locations,
                          rec_email: rec_email,
                          rec_name: rec_name,
                          tl_name: tl_name,
                          tl_email: tl_email,
                          sourcer_name: sourcer_name,
                          sourcer_email: sourcer_email,
                          am_email: am_email,
                          am_name: this.am_name,
                          added_on:  moment(added_on).format("DD MMM YYYY"),
                          selection_date: can.selection_date ? moment(can.selection_date).format("DD MMM YYYY") : 'Not Available',
                          offered_date: this.offeredDate ? moment(this.offeredDate).format("DD MMM YYYY") : 'Not Available',
                          joining_date: this.joiningDate ? moment(this.joiningDate).format("DD MMM YYYY") : 'Not Available',
                          offered_ctc: this.offeredCTC ? this.offeredCTC : 'Not Available',
                          source: can.source
                        }
                        //console.log("in if mail-1", mailData)
                        let mail = emailTemplates.offeredInternalMail(mailData)
                        const mail_body = {
                            ...mail, // Use Sequelize's .dataValues to get a plain object of the model
                            timeline_id: updateCandidateResponse.timeline // Append the newly created timeline object
                        };
                        //console.log("in if mail-2", mail)
                        if (mail && process.env.NODE_ENV === "production") {
                          mailService.mail(mail_body).then(res => {
                            //console.log("in if mail-2", res)
                          })
                        }
                      //}
                    }
                } else if (this.statusCSelected === "Joined" && this.jobId) {
                    
                    //console.log('recruiter',rec, rec_email, am_email, rec_name)
                    if(rec_email) {
                      //console.log('client', this.client, this.locations, this.attachments, this.position_name, this.rec_name)
                      //if( this.client !== 'LTI RPO' && this.client !== 'Dummy' && this.client !== 'Sonata Technologies'  && this.client !== 'Wipro RPO') {
                        const mailData = {
                          can_email: this.candidateData.email,
                          can_fname: `${this.candidateData.first_name} ${this.candidateData.last_name}`,
                          can_contact: this.candidateData.contact_no,
                          can_id: this.candidateData.candidate_id,
                          position_name: this.position_name,
                          job_id: this.jobCode,
                          client: this.client,
                          location: this.locations,
                          rec_email: rec_email,
                          rec_name: rec_name,
                          tl_name: tl_name,
                          tl_email: tl_email,
                          sourcer_name: sourcer_name,
                          sourcer_email: sourcer_email,
                          am_email: am_email,
                          am_name: this.am_name,
                          added_on:  moment(added_on).format("DD MMM YYYY"),
                          selection_date: can.selection_date ? moment(can.selection_date).format("DD MMM YYYY") : 'Not Available',
                          offered_date: can.offered_date ? moment(can.offered_date).format("DD MMM YYYY") : 'Not Available',
                          joining_date: this.joiningDate ? moment(this.joiningDate).format("DD MMM YYYY") : 'Not Available',
                          offered_ctc: can.offered_ctc ? can.offered_ctc : 'Not Available',
                          revenue: can.revenue ? can.revenue : 'Not Available',
                          source: can.source
                        }
                        console.log("in if mail-1", mailData)
                        let mail = emailTemplates.joinedInternalMail(mailData)
                        const mail_body = {
                            ...mail, // Use Sequelize's .dataValues to get a plain object of the model
                            timeline_id: updateCandidateResponse.timeline // Append the newly created timeline object
                        };
                        console.log("in if mail-2", mail)
                         if (mail && process.env.NODE_ENV === "production") {
                          mailService.mail(mail_body).then(res => {
                            //console.log("in if mail-2", res)
                          })
                         }
                      //}
                    }
                } else if ((this.statusCSelected === "OfferDrop" || this.statusCSelected === 'NotOffered' || this.statusCSelected === 'Early Attrition' || this.statusCSelected === 'Attrition') && this.jobId) {
                    
                    //console.log('recruiter',rec, rec_email, am_email, rec_name)
                    if(rec_email) {
                      //console.log('client', this.client, this.locations, this.attachments, this.position_name, this.rec_name)
                      //if( this.client !== 'LTI RPO' && this.client !== 'Dummy' && this.client !== 'Sonata Technologies'  && this.client !== 'Wipro RPO') {
                        const mailData = {
                          can_email: this.candidateData.email,
                          can_fname: `${this.candidateData.first_name} ${this.candidateData.last_name}`,
                          can_contact: this.candidateData.contact_no,
                          can_id: this.candidateData.candidate_id,
                          position_name: this.position_name,
                          job_id: this.jobCode,
                          client: this.client,
                          location: this.locations,
                          rec_email: rec_email,
                          rec_name: rec_name,
                          tl_name: tl_name,
                          tl_email: tl_email,
                          sourcer_name: sourcer_name,
                          sourcer_email: sourcer_email,
                          am_email: am_email,
                          am_name: this.am_name,
                          added_on:  moment(added_on).format("DD MMM YYYY"),
                          selection_date: can.selection_date ? moment(can.selection_date).format("DD MMM YYYY") : 'Not Available',
                          offered_date: can.offered_date ? moment(can.offered_date).format("DD MMM YYYY") : 'Not Available',
                          joining_date: can.joining_date ? moment(can.joining_date).format("DD MMM YYYY") : 'Not Available',
                          drop_reason: this.dropReason,
                          offered_ctc: can.offered_ctc ? can.offered_ctc : 'Not Available',
                          revenue: can.revenue ? can.revenue : 'Not Available',
                          source: can.source,
                          status: this.statusCSelected
                        }
                        
                        //console.log("in if mail-1", mailData)
                        let mail = emailTemplates.offerDropInternalMail(mailData)
                        // mail.cid = this.id
                        // mail.jid = this.jobId
                        const mail_body = {
                            ...mail, // Use Sequelize's .dataValues to get a plain object of the model
                            timeline_id: updateCandidateResponse.timeline // Append the newly created timeline object
                        };
                        //console.log("in if mail-2", mail)
                        if (mail && process.env.NODE_ENV === "production") {
                          mailService.mail(mail_body).then(res => {
                            //console.log("in if mail-2", res)
                          })
                        }
                      //}
                    }
                }
                else if (this.statusCSelected === "Document Submitted"  && this.jobId) {
                    
                    //console.log('recruiter',rec, rec_email, am_email, rec_name)
                    if(rec_email) {
                      //console.log('client', this.client, this.locations, this.attachments, this.position_name, this.rec_name)
                      //if( this.client !== 'LTI RPO' && this.client !== 'Dummy' && this.client !== 'Sonata Technologies'  && this.client !== 'Wipro RPO') {
                        const mailData = {
                          can_email: this.candidateData.email,
                          can_fname: `${this.candidateData.first_name} ${this.candidateData.last_name}`,
                          can_contact: this.candidateData.contact_no,
                          can_id: this.candidateData.candidate_id,
                          position_name: this.position_name,
                          job_id: this.jobCode,
                          client: this.client,
                          location: this.locations,
                          rec_email: rec_email,
                          rec_name: rec_name,
                          tl_name: tl_name,
                          tl_email: tl_email,
                          status: this.statusCSelected,
                          url: 'https://ats.sattvahuman.in/#' + this.$route.fullPath
                        }
                        console.log("in if mail-1", mailData)
                        let mail = emailTemplates.documentSubmittedMail(mailData)
                        // mail.cid = this.id
                        // mail.jid = this.jobId
                        const mail_body = {
                            ...mail, // Use Sequelize's .dataValues to get a plain object of the model
                            timeline_id: updateCandidateResponse.timeline // Append the newly created timeline object
                        };
                        console.log("in if mail-2", mail)
                        if (mail && process.env.NODE_ENV === "production") {
                          if (mail) {  
                            mailService.mail(mail_body).then(res => {
                              //console.log("in if mail-2", res)
                            })
                          }
                        }
                    }
                }
              this.statusCSelected = null

//              })
              CandidateService.getCandidateCurrentStatus(this.id, this.jobId).then( res => {
                if (res.status === 'OK') {
                  //console.log('candidate status', res)
                  this.prevStatus = res.data[0].candidateStatus
                }
              })
              this.getCandidateData();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Candidate Status Updated",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Candidate Status Update Failed",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          }
      );
    }  else {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed!!! " + this.error,
              icon: "EditIcon",
              variant: "danger",
            },
          });
    }
    this.NI_NR_comment = null
    },
    doCopy(email) {
      this.$copyText(email).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email copied',
            icon: 'BellIcon',
          },
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
    CandidateBelongsToJob() {
        
        //console.log('check update button visible')
        const isAssignedToThisJob = this.candidateJobs.filter(item => item.id == this.jobId)
        let visible = false;
        const currentUserRole = JSON.parse(localStorage.getItem("userData")).roles[0];
        const empId = JSON.parse(localStorage.getItem("userData")).emp_id;
        let currentStatus = ''

        if (isAssignedToThisJob.length > 0) {
          currentStatus = isAssignedToThisJob[0].candidateStatus

          if(currentStatus === 'ReviewReject' || currentStatus === 'ScreenReject' || currentStatus === 'Duplicate' || currentStatus === 'Rejected' || currentStatus === 'NotOffered' || currentStatus === 'OfferDrop' || currentStatus === 'UploadedBySourcer' || currentStatus === 'UploadedByRecruiter' || currentStatus === 'AssignedByMassMail' || currentStatus === 'Not Relevant' || currentStatus === 'Not Interested' || currentStatus === 'Not Answered' || currentStatus === 'Not Interested' || currentStatus === 'Not Reachable' || currentStatus === 'Wrong Number'  || currentStatus === 'Call Back' || currentStatus === 'Drop')   {
            visible = false
            return
          }
          //console.log(isAssignedToThisJob, currentUserRole, empId, currentStatus)  
          //console.log(isAssignedToThisJob.length > 0 &&  (currentUserRole === 'ADMIN' || currentUserRole === 'MASTER' || currentUserRole === 'MIS' || currentUserRole === 'HR') && (currentStatus !== 'UploadedBySourcer' || currentStatus !== 'UploadedByRecruiter'  || currentStatus !== 'AssignedByMassMail' || currentStatus !== 'Not Answered' || currentStatus !== 'Not Reachable' || currentStatus !== 'Wrong Number'  || currentStatus !== 'Call Back' || currentStatus === 'ReviewReject' || currentStatus === 'ScreenReject' || currentStatus === 'Rejected' || currentStatus === 'Duplicate' || currentStatus === 'NotOffered' || currentStatus === 'Joined' || currentStatus === 'OfferDrop'))
        } else {
          //console.log('Not assigned to this Job')
        }
        if (isAssignedToThisJob.length > 0 && (this.$can('read','admin') || this.$can('read','master') || this.$can('read','hr_head')) && (currentStatus !== 'UploadedBySourcer' && currentStatus !== 'UploadedByRecruiter' && currentStatus !== 'AssignedByMassMail' && currentStatus !== 'Not Answered' && currentStatus !== 'Not Interested' && currentStatus !== 'Not Reachable' && currentStatus !== 'Wrong Number'  && currentStatus !== 'Call Back' )) {
        // if (isAssignedToThisJob.length > 0 && (currentUserRole === 'ADMIN' || currentUserRole === 'MASTER' || currentUserRole === 'HR') && (currentStatus !== 'UploadedBySourcer' && currentStatus !== 'UploadedByRecruiter' && currentStatus !== 'AssignedByMassMail' && currentStatus !== 'Not Answered' && currentStatus !== 'Not Interested' && currentStatus !== 'Not Reachable' && currentStatus !== 'Wrong Number'  && currentStatus !== 'Call Back' )) {
          visible = true
          //console.log('ADMIN visisble', visible)
        } else if (isAssignedToThisJob.length > 0 && (currentStatus !== 'UploadedBySourcer' || currentStatus !== 'UploadedByRecruiter' || currentStatus !== 'AssignedByMassMail' || currentStatus !== 'AssignedByATSSourcer' || currentStatus !== 'AssignedByATSMassMailer' || currentStatus !== 'Not Answered' || currentStatus !== 'Not Reachable' || currentStatus !== 'Wrong Number'  || currentStatus !== 'Call Back' || currentStatus === 'ReviewReject' || currentStatus === 'ScreenReject' || currentStatus === 'Rejected' || currentStatus === 'Duplicate' || currentStatus === 'NotOffered' || currentStatus === 'Joined' || currentStatus === 'OfferDrop')) {  
          // if(this.client === 'Sattva Human Pvt Ltd' && (currentUserRole === 'TALENT-ADVISOR' || currentUserRole === 'TALENT-ADVISOR-SOURCER' || currentUserRole === 'ACCOUNT-MANAGER' || currentUserRole === 'PROGRAM-MANAGER' || currentUserRole === 'MIS')) {
          if(this.client === 'Sattva Human Pvt Ltd' && (this.$can('read','talent_acquisition') || this.$can('read','account_manager') || this.$can('read','program_manager') || this.$can('read','mis'))) {
            visible = false
            this.showCandidateDetails = false
          }
          const hasCurrentUserAdded = isAssignedToThisJob[0].recruiter
          const isAMForTheJob = JSON.parse(isAssignedToThisJob[0].account_mgr)[0].emp_id
          const isMgr = isAssignedToThisJob[0].mgr_id
          //console.log('mgr', isMgr)
          const isPMgr = isAssignedToThisJob[0].p_mgr_id
          const hascurentUserUpdated = isAssignedToThisJob[0].sourcer
          //console.log(hasCurrentUserAdded, isAMForTheJob)
          //console.log('can job update', isAssignedToThisJob, currentUserRole, currentStatus, hasCurrentUserAdded, isAMForTheJob, empId)
          if((hasCurrentUserAdded === empId || isAMForTheJob === empId || isMgr === empId || hascurentUserUpdated === empId)) {
            if(isAMForTheJob === empId || isMgr === empId || currentUserRole === 'ACCOUNT-MANAGER') {
              visible = true
              // if(this.client === 'Sattva Human Pvt Ltd' && (currentUserRole === 'TALENT-ADVISOR' || currentUserRole === 'TALENT-ADVISOR-SOURCER' || currentUserRole === 'ACCOUNT-MANAGER' || currentUserRole === 'PROGRAM-MANAGER' || currentUserRole === 'MIS')) {
              if(this.client === 'Sattva Human Pvt Ltd' && (this.$can('read','talent_acquisition') || this.$can('read','account_manager') || this.$can('read','program_manager') || this.$can('read','mis'))) {
                visible = false
                this.showCandidateDetails = false
              }
            }
            if((this.$can('read','talent_acquisition') || this.$can('read','mis')) && currentStatus !== 'Initiated') {
              visible = true
              if(this.client === 'Sattva Human Pvt Ltd' && (this.$can('read','talent_acquisition') || this.$can('read','account_manager') || this.$can('read','program_manager') || this.$can('read','mis'))) {
                visible = false
                this.showCandidateDetails = false
              }
            } else if ((!this.$can('read','talent_acquisition')) && currentStatus === 'Initiated') {
            // } else if ((currentUserRole !== 'TALENT-ADVISOR' || currentUserRole !== 'TALENT-ADVISOR-SOURCER') && currentStatus === 'Initiated') {
              if(this.client === 'Sattva Human Pvt Ltd' && (this.$can('read','talent_acquisition') || this.$can('read','account_manager') || this.$can('read','program_manager') || this.$can('read','mis'))) {
                visible = false
                this.showCandidateDetails = false
              }
              //this.pausedFor7Seconds = true
              visible = true
              setTimeout(() => this.pausedFor7Seconds = false, 7000);
              
            } else if ((!this.$can('read','talent_acquisition')) && currentStatus !== 'Initiated') {
              visible = true
            } else {
              visible = false
            }
          } else {
            visible = false
          }
        } else if (this.vertical === 'Infra Industry' && this.$can('update','vertical_infra') && this.$can('update','vertical_data')) {
          visible = true
        } else if (this.vertical === 'Service Industry' && this.$can('update','vertical_service') && this.$can('update','vertical_data')) {
          visible = true
        } else if ((this.vertical === 'Service Industry' || this.vertical === 'Infra Industry') && this.$can('manage','vertical_service') && this.$can('manage','vertical_data')) {
          visible = true
        }
        else {
          visible = false
        }
        
      
      return visible //this.candidateData.jobs.filter(item => item.id == this.jobId)
    },
    CandidateBelongsToSourcerJob() {
      
        const isAssignedToThisJob = this.candidateJobs.filter(item => item.id == this.jobId)
        // console.log('assign to job in belongs to sourcer: ',isAssignedToThisJob)
        let currentStatus = ''
        if (isAssignedToThisJob.length > 0) {
          currentStatus = isAssignedToThisJob[0].candidateStatus
        }
        let visible = false;
        if((currentStatus === 'UploadedBySourcer' || currentStatus === 'UploadedByRecruiter' || currentStatus === 'AssignedByMassMail' || currentStatus === 'AssignedByATSSourcer' || currentStatus === 'AssignedByATSMassMailer' ||currentStatus === 'Not Answered' || currentStatus === 'Not Reachable' || currentStatus === 'Wrong Number'  || currentStatus === 'Call Back' || currentStatus === 'Not Interested' || currentStatus === 'Not Relevant' || currentStatus === 'Duplicate') && isAssignedToThisJob.length > 0) {
          const currentUserRole = JSON.parse(localStorage.getItem("userData")).roles[0];
          const empId = JSON.parse(localStorage.getItem("userData")).emp_id;
          const isAMForTheJob = JSON.parse(isAssignedToThisJob[0].account_mgr)[0].emp_id
          const hasCurrentUserAdded = isAssignedToThisJob[0].sourcer
          const isRecruiterForTheJob = JSON.parse(isAssignedToThisJob[0].recruiters).filter(item => item.emp_id === empId)
          console.log('can job', empId, isAssignedToThisJob, currentUserRole, currentStatus, hasCurrentUserAdded, isAMForTheJob, isRecruiterForTheJob)
          
          //const isAMForTheJob = JSON.parse(isAssignedToThisJob[0].account_mgr)[0].emp_id
          let isSourcerForTheJob = ''
          //console.log('sourcer', this.isArray(isAssignedToThisJob[0].sourcer), isAssignedToThisJob[0].sourcer, typeof isAssignedToThisJob[0].sourcer)
          if (this.isArray(isAssignedToThisJob[0].sourcers) || typeof isAssignedToThisJob[0].sourcers === 'string') {
            ////console.log('sourcer',JSON.parse(isAssignedToThisJob[0].sourcer))
            //console.log('in array or string check')
            isSourcerForTheJob = JSON.parse(isAssignedToThisJob[0].sourcers).filter(item => item.emp_id === empId)
            //console.log(isSourcerForTheJob )
          }       
          //console.log('after sourcer check', currentUserRole === 'ADMIN', currentUserRole === 'MASTER', hasCurrentUserAdded === empId,  isAMForTheJob === empId,  isSourcerForTheJob, isRecruiterForTheJob)   

          // if (currentUserRole === 'ADMIN' || currentUserRole === 'MASTER' || hasCurrentUserAdded === empId || isAMForTheJob === empId ||  isSourcerForTheJob || isRecruiterForTheJob ) {
          if (this.$can('read','admin') || this.$can('read','master') || hasCurrentUserAdded === empId || isAMForTheJob === empId ||  isSourcerForTheJob || isRecruiterForTheJob  ) {
            //console.log('in all check success')
            visible = true
          } else if (this.vertical === 'Infra Industry' && this.$can('update','vertical_infra') && this.$can('update','vertical_data')) {
            visible = true
          } else if (this.vertical === 'Service Industry' && this.$can('update','vertical_service') && this.$can('update','vertical_data')) {
            visible = true
          } else if ((this.vertical === 'Service Industry' || this.vertical === 'Infra Industry') && this.$can('manage','vertical_service') && this.$can('manage','vertical_data')) {
            visible = true
          }
        }
        else {
          visible = false;
          //console.log('in all check failed')
        }
        return visible //this.candidateData.jobs.filter(item => item.id == this.jobId)
    },
    isArray(a) {
      return (!!a) && (a.constructor === Array);
    },
    isObject(a) {
      return (!!a) && (a.constructor === Object);
    },
    updateCandidateNINRReason() {
      if (this.NI) {
        this.statusCSelected = 'Not Interested'
        if(this.NI_Reason_Selected !== '' & this.NI_NR_comment !== '') {
          this.updateCandidateStatus()
        } else {
          this.error_NI_NR_comment = 'Reason & comment is mandatory'
          this.$refs["modal-ni-nr-reason"].show();
        }
      } else if (!this.NI) {
        this.statusCSelected = 'Not Relevant'
        if(this.NI_NR_comment !== '') {
          this.updateCandidateStatus()
        } else {
          this.error_NI_NR_comment = 'Comment is mandatory'
          this.$refs["modal-ni-nr-reason"].show();
        }
      }
    },
    showDocuploadModal(id) {
      this.$refs["modal-doc-upload"].show();
    },
    // showGenerateOfferModal(id) {
    //   this.$refs["modal-generate-offer-letter"].show();
    // },
    uploadAadhaar() {
     console.log('in upload Docs', this.documents.aadhaarCard)
      const formData = new FormData();
      formData.append('aadhaarFile', this.documents.aadhaarCard)
      formData.append('id', this.id)
      
      CandidateService.uploadInfraCanAadhaar(formData).then(res => {
        console.log('in called api:', res.data)
        if(res.status === 200) {
          this.aadhaarUploaded = true
          this.showAadhaarUpload = false
          CandidateService.getCandidateDocuments(this.id).then(res => {
            if(res.status === 200) {
              this.candidateData.documents = res.data.data.documents
            }
          })
        }
      })
    },
    uploadPan() {
     console.log('in upload Docs')
      const formData = new FormData();

      formData.append('panFile', this.documents.panCard)
      formData.append('id', this.id)

      CandidateService.uploadInfraCanPan(formData).then(res => {
        console.log('in called api:', res)
        if(res.status === 200) {
          this.panUploaded = true
          this.showPanUpload = false
          CandidateService.getCandidateDocuments( this.id).then(res => {
            if(res.status === 200) {
              this.candidateData.documents = res.data.data.documents
            }
          })
        }
      })
    },
    uploadPhoto() {
     console.log('in upload Docs')
      const formData = new FormData();

      formData.append('photoFile', this.documents.passportPhoto)
      formData.append('id', this.id)

      CandidateService.uploadInfraCanPhoto(formData).then(res => {
        console.log('in called api:', res)
        if(res.status === 200) {
          this.photoUploaded = true
          this.showPhotoUpload = false
          CandidateService.getCandidateDocuments( this.id).then(res => {
            if(res.status === 200) {
              this.candidateData.documents = res.data.data.documents
            }
          })
        }
      })
    },
    uploadCertificate() {
     console.log('in upload Docs')
      const formData = new FormData();

      formData.append('certificateFile', this.documents.skillCertificate)
      formData.append('id', this.id)

      CandidateService.uploadInfraCanCertificate(formData).then(res => {
        console.log('in called api:', res)
        if(res.status === 200) {
          this.certificateUploaded = true
          this.showCertificateUpload = false
          CandidateService.getCandidateDocuments( this.id).then(res => {
            if(res.status === 200) {
              this.candidateData.documents = res.data.data.documents
            }
          })
        }
      })
    },
    uploadBank() {
     console.log('in upload Docs')
      const formData = new FormData();

      formData.append('bankFile', this.documents.bankProof)
      formData.append('id', this.id)

      CandidateService.uploadInfraCanBank(formData).then(res => {
        console.log('in called api:', res)
        if(res.status === 200) {
          this.bankUploaded = true
          this.showBankUpload = false
          CandidateService.getCandidateDocuments(this.id).then(res => {
            if(res.status === 200) {
              console.log('candidate docs bank: ',res.data)
              this.candidateData.documents = res.data.data.documents
            }
          })
        }
      })
    },
    reUploadAadhaar() {
      this.showAadhaarUpload = !this.showAadhaarUpload
    },
    reUploadPan() {
      this.showPanUpload = !this.showPanUpload
    },
    reUploadPhoto() {
      this.showPhotoUpload = !this.showPhotoUpload
    },
    reUploadCertificate() {
      this.showCertificateUpload = !this.showCertificateUpload
    },
    reUploadBank() {
      this.showBankUpload = !this.showBankUpload
    },
    previewFile(type) {
      let key = null
      if(type === 'aadhaar') {
        key = this.candidateData.documents.aadhaar.replace("/","%2F")
      }
      if(type === 'pan') {
        key = this.candidateData.documents.pan.replace("/","%2F")
      }
      if(type === 'photo') {
        key = this.candidateData.documents.photo.replace("/","%2F")
      }
      if(type === 'skillCertificate') {
        key = this.candidateData.documents.skillCertificate.replace("/","%2F")
      }
      if(type === 'bankProof') {
        key = this.candidateData.documents.bankProof.replace("/","%2F")
      }
      const temp = key.split(".");
      const cStatus = this.candidateJobs.filter(item => item.id === this.jobId )[0]
      this.prevStatus = cStatus ? cStatus.candidateStatus : ''
      const extension = temp[temp.length - 1]; 

      const fileFormat = extension === 'jpg' ? 'image/jpg' : extension === 'jpeg' ? 'image/jpeg' : extension === 'png' ? 'image/png' : extension === 'pdf' ? 'application/pdf' : ''

      JobService.getFileBase64(key)
      .then(res => {
        ////console.log(res)
        const base64 = res.file;
        //var encodedUrl = encodeURIComponent(res.file);
        this.canDocLink = "data:" + fileFormat +";base64," + base64
                  
      });
      // JobService.getFile(key)
      // .then(res => {
      //   this.docDownloadlink = res.file;
      // })
      this.$refs["doc-preview-modal"].show();
    },
    downloadFile(type) {
      this.canDocLink = null
      let key = null
      if(type === 'aadhaar') {
        key = this.candidateData.documents.aadhaar.replace("/","%2F")
      }
      if(type === 'pan') {
        key = this.candidateData.documents.pan.replace("/","%2F")
      }
      if(type === 'photo') {
        key = this.candidateData.documents.photo.replace("/","%2F")
      }
      if(type === 'skillCertificate') {
        key = this.candidateData.documents.skillCertificate.replace("/","%2F")
      }
      if(type === 'bankProof') {
        key = this.candidateData.documents.bankProof.replace("/","%2F")
      }
      const temp = key.split(".");
      const cStatus = this.candidateJobs.filter(item => item.id === this.jobId )[0]
      this.prevStatus = cStatus ? cStatus.candidateStatus : ''
      const extension = temp[temp.length - 1]; 

      const fileFormat = extension === 'jpg' ? 'image/jpg' : extension === 'jpeg' ? 'image/jpeg' : extension === 'png' ? 'image/png' : extension === 'pdf' ? 'application/pdf' : ''
      
      JobService.getFileBase64(key)
      .then(res => {
        ////console.log(res)
        const base64 = res.file;
        //var encodedUrl = encodeURIComponent(res.file);
        this.canDocLink = "data:" + fileFormat +";base64," + base64
        const a = document.createElement('a')
        a.href = this.canDocLink 
        a.download = `${key}` // File name Here
        a.click()
      });
      
    },
    // computeDocumentDone() {
    //   console.log('in compute', this.prevStatus)
    //   if(this.prevStatus === 'Confirmed') {
    //     console.log('in compute if loop')
    //     if(this.candidateData.documents.aadhaar  && this.candidateData.documents.pan && this.candidateData.documents.photo && (this.candidateData.documents.skillCertificate || !this.skillCertificateAvailable) && this.candidateData.documents.bankProof) {
    //       this.statusCSelected = 'Document Submitted'
    //       this.updateCandidateStatus()
    //       this.prevStatus = this.statusCSelected
    //     }
    //   }
    // },
    // checkAllDocSubmitted() {
    //   if(this.candidateData.documents.aadhaar  && this.candidateData.documents.pan && this.candidateData.documents.photo && (this.candidateData.documents.skillCertificate || !this.skillCertificateAvailable) && this.candidateData.documents.bankProof) {
    //     this.statusCSelected = 'Document Submitted'
    //     this.updateCandidateStatus()
    //     this.prevStatus = this.statusCSelected
    //   }
    // },
    setContractStartDate() {
      if(this.positionType === 'Contractual' && this.payroll === 'Sattva Payroll' && this.vertical === 'Service Industry' && this.category === 'White Collar') {
        this.contractStartDate = this.joiningDate
      } 
    },
    // previewOfferLetter() {

    // }
  },
  computed: {
    formatDate() {
      return moment(this.candidateData.createdAt).format("DD MMM YYYY HH:mm:ss")
    }
  }
};
</script>

<style lang="scss" scoped>

  .card-body {
    padding: 1rem !important;
  }
  .timeline-item {
    padding-bottom: 15px;
  }
  .text-muted {
    color: #9c9ca8 !important;
  }
  .bitem {
    color: #00ac49 !important;
  }
  .btn {
    padding: 0.45rem 0.55rem !important;
    font-size: 0.88rem;
    line-height: 1.1;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>