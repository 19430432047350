import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.63:5000/api/';
// /*prod*/ //const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.63:5000/api/';
}
class MassMailerService {
    
    async createMailer(data) {
        try {
            const res = await axios.post(API_URL + 'mass-mailer', data,
            {
                headers: AuthService.authHeader()
            })
            console.log(res.data)
            return res.data
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }

    async getAllMails(rec_id) {
        try {
            const res = await axios.get(API_URL + 'mass-mailer/?rec_id=' + rec_id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async updataMailer(data) {
        try {
            const res = await axios.put(API_URL + 'mass-mailer/', data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async delete(data) {
        try {
            const res = await axios.delete(API_URL + 'mass-mailer/', data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

}

export default new MassMailerService();