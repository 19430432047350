import axios from 'axios';
import AuthService from './auth-header';

// /*dev*/const API_URL = 'http://192.168.1.63:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.63:5000/api/';
}
class clientRecruiterListService {

    async create(data) {
        try {
          const res = await axios.post(API_URL + 'jobs/client/recruiters', data,
            {
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response);
            return err.reponse;
        }
    }

    async getAllRecruiters(recruiter,client) {
        try {
            const response = await axios.get(API_URL + 'jobs/client/recruiters?recruiter=' + recruiter + '&client=' + client, 
            {
                headers: AuthService.authHeader(),
            });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error(error.response);
            return error.response;
        }
    }

    async updateRecruiter(id, data) {
        try {
            const response = await axios.put(API_URL + 'jobs/client/recruiters/' + id, data, 
            {
                headers: AuthService.authHeader(),
            });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error(error.response);
            return error.response;
        }
    }

    async deleteRecruiter(id) {
        try {
            const response = await axios.delete(API_URL + 'jobs/client/recruiters/' + id, 
            {
                headers: AuthService.authHeader(),
            });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error(error.response);
            return error.response;
        }
    }
    
}

export default new clientRecruiterListService();