import axios from 'axios';
import AuthService from './auth-header';

// /*dev*/const API_URL = 'http://192.168.1.63:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.63:5000/api/';
}
class reportService {

    async getWorkTracker(v) {
        try {
          //console.log(id)
          const res = await axios.get(API_URL + 'report/tracker/' + v, 
          { 
            headers: AuthService.authHeader() 
          });
          console.log(res.data)
          return res.data
        }
        catch (err) {
          console.log(err.response);
          return err.response
        }
      }

      async getRecTracker(startDate, endDate, emp_id) {
        try {
          //console.log(id)
          const res = await axios.get(API_URL + 'report/recruiter/tracker?startDate=' + startDate + '&endDate=' + endDate + '&emp_id=' + emp_id,
          { 
            headers: AuthService.authHeader() 
          });
          console.log(res.data)
          return res.data
        }
        catch (err) {
          console.log(err.response);
          return err.response
        }
      }  
      async getSourcerTracker(startDate, endDate) {
        try {
          //console.log(id)
          const res = await axios.get(API_URL + 'report/sourcer/tracker?startDate=' + startDate + '&endDate=' + endDate ,
          { 
            headers: AuthService.authHeader() 
          });
          console.log(res.data)
          return res.data
        }
        catch (err) {
          console.log(err.response);
          return err.response
        }
      }  
      
      async getStatusWiseTracker(startDate, endDate, rec, mgr, loc, client, source, position, recSel, mgrSel, clientSel, locSel, sourceSel, empStatusSel) {
        try {
          //console.log(id)
          const res = await axios.get(API_URL + 'report/status-wise-tracker?startDate=' + startDate + '&endDate=' + endDate + '&recruiter=' + rec + '&manager=' + mgr + '&location=' + loc + '&client=' + client + '&position=' + position + '&source=' + source + '&recSelected=' + recSel + '&mgrSelected=' + mgrSel + '&locSelected=' + locSel + '&clientSelected=' + clientSel + '&sourceSelected=' + sourceSel + '&empStatusSelected=' + empStatusSel,
          { 
            headers: AuthService.authHeader() 
          });
          console.log(res.data)
          return res.data
        }
        catch (err) {
          console.log(err.response);
          return err.response
        }
      }

}
export default new reportService